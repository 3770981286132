<div class="entity-actions">
  <button
    class="action-button action-button-delete"
    title="Delete Document"
    [disabled]="isControlsDisabled | accessControl | async"
    (click)="btnClicked.emit('DELETE')"
  >
    <rkt-icon name="trash"></rkt-icon>
  </button>
</div>
