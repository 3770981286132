import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, throwError, timer } from 'rxjs';
import { catchError, defaultIfEmpty, map, tap, switchMap, retry, exhaustMap } from 'rxjs/operators';
import { HttpParams, HttpContext } from '@angular/common/http';
import { compact, isArray, omit } from 'lodash-es';
import { utcToZonedTime } from 'date-fns-tz';
import { Store } from '@ngrx/store';
import { environment } from '@env';
import { constants } from '@shared/constants';
import { CUSTOM_ERROR_MESSAGE, IS_EXTENDED_ERRORS } from '@shared/interceptors';
import {
  FinancialAccountsRequest,
  BusinessAccount,
  FinancialAccountList,
  FinancialAccountListRaw,
  FinancialAccountCreateData,
  FinancialAccountDetailsRaw,
  FinancialAccountDetails,
  RecipientFinancialAccountsRequest,
  Recipient,
  FinancialAccountBalances,
  FinancialAccountDetailsWithBalancesRaw,
  FinancialAccountActivitiesRequest,
  FinancialAccountActivitiesRaw,
  FinancialAccountActivities,
  Customer,
  CustomerFinancialAccountsRequest,
  FinancialAccountWithAccountHolderList,
  FinancialAccountWithAccountHolderListRaw,
  EntityFinancialAccountDetailsRaw,
  FilterValues,
  Address,
  IntegratedFinancialAccountCreateData,
  FinancialAccountDetailsWithBalances,
  FinancialAccountStatements,
  FinancialAccountStatementsRaw,
  FinancialAccountStatementsRequest,
  FinancialAccountStatementDetailsRaw,
  FinancialAccountStatementDetails,
  FinancialAccountUpdateData,
  VerificationRequestResponse,
} from '@shared/models';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  BusinessAccountService,
  CustomerService,
  NotificationService,
  RecipientService,
  StorageService,
  TransactionLimitsService,
} from '@shared/services';
import { AuditActions, fromAuth } from '@shared/store';
import { ErrorUtils, systemTimeZone } from '@shared/utils';
import { BackendService } from '@shared/services/backend.service';
import {
  financialAccountActivitiesRequestParams,
  financialAccountsWithBalancesRequestParams,
  getTransactionLimit,
  mapFinancialAccountActivities,
  mapFinancialAccountBalances,
  mapFinancialAccountDetails,
  mapFinancialAccountList,
  mapFinancialAccountListWithEntities,
  mapFinancialAccountStatements,
} from './financial-account-mapping-utils';

@Injectable({
  providedIn: 'root',
})
export class FinancialAccountService {
  activeActivityFilters = new BehaviorSubject<FilterValues>({});

  private readonly store = inject(Store);

  constructor(
    private businessAccountService: BusinessAccountService,
    private backendService: BackendService,
    private recipientService: RecipientService,
    private customerService: CustomerService,
    private limitsService: TransactionLimitsService,
    private storageService: StorageService,
    private notificationService: NotificationService,
  ) {}

  public getFinancialAccounts({
    page,
    size,
    type,
    state,
    accountTypes,
    accountRoles,
    accountHolderTypes,
  }: FinancialAccountsRequest): Observable<FinancialAccountList> {
    const pageSize = size ?? constants.FINANCIAL_ACCOUNT_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    if (type) {
      params = params.append('type', type);
    }

    if (state) {
      params = params.append('state', isArray(state) ? state.join(',') : state);
    }

    if (accountTypes) {
      params = params.append('categories', accountTypes.join(','));
    }

    if (accountRoles) {
      params = params.append('accountRoles', accountRoles.join(','));
    }

    if (accountHolderTypes) {
      params = params.append('accountHolderTypes', accountHolderTypes.join(','));
    }

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountListRaw>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts`, {
            params,
          })
          .pipe(
            map((financialData) => mapFinancialAccountList(financialData)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getFinancialAccountsIds(accountHolderId: string): Observable<string[]> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('accountHolderId', `${accountHolderId}`);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<string[]>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/ids`, {
            params,
          })
          .pipe(
            map((financialAccountIds) => financialAccountIds),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getFinancialAccountsWithLimitsAndBalance({
    page,
    size,
    type,
    state,
    accountTypes,
  }: FinancialAccountsRequest): Observable<FinancialAccountList> {
    const pageSize = size ?? constants.FINANCIAL_ACCOUNT_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    if (type) {
      params = params.append('type', type);
    }

    if (state) {
      params = params.append('state', isArray(state) ? state.join(',') : state);
    }

    if (accountTypes) {
      params = params.append('categories', accountTypes.join(','));
    }

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountListRaw>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts`, {
            params,
          })
          .pipe(
            switchMap((financialAccountsResponse) => {
              const items = financialAccountsResponse.content ?? [];
              return forkJoin(
                items.map((item) => {
                  return this.getFinancialAccountBalancesWithAccountInfo(item.id).pipe(
                    tap({
                      next: ({ accountBalances }) => {
                        item.accountBalance = accountBalances?.['USD']?.accountBalance;
                        item.availableBalance = accountBalances?.['USD']?.availableBalance;
                      },
                    }),
                    catchError((error) => {
                      ErrorUtils.catchError('getFinancialAccountBalancesWithAccountInfo error', error);
                      return of({});
                    }),
                  );
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => financialAccountsResponse),
              );
            }),
            switchMap((financialAccountsResponse) => {
              const items = financialAccountsResponse.content ?? [];
              return forkJoin(
                items.map((item) => {
                  return item.category === 'EXTERNAL'
                    ? of(item)
                    : this.limitsService.getTransactionLimits(item.id, 'FA_KFA').pipe(
                        tap({
                          next: (limits) => {
                            item.single = getTransactionLimit('single', limits);
                            item.daily = getTransactionLimit('daily', limits);
                            item.weekly = getTransactionLimit('weekly', limits);
                            item.monthly = getTransactionLimit('monthly', limits);
                          },
                        }),
                        catchError(() => of({})),
                      );
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => {
                  return mapFinancialAccountList(financialAccountsResponse);
                }),
              );
            }),
          ),
      ),
    );
  }

  public getFinancialAccountsWithBalances(requestParams: FinancialAccountsRequest): Observable<FinancialAccountList> {
    const params = financialAccountsWithBalancesRequestParams(requestParams);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountListRaw>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts`, {
            params,
          })
          .pipe(
            retry({ count: 3, delay: (_error, attempt) => timer(1000 * attempt) }),
            switchMap((financialAccountsResponse) => {
              const items = financialAccountsResponse.content ?? [];
              let balanceErrorsCount = 0;
              return forkJoin(
                items.map((item) => {
                  if (item.category === 'EXTERNAL') {
                    return of(item);
                  }
                  return this.getFinancialAccountBalancesWithAccountInfo(item.id).pipe(
                    retry({ count: 3, delay: (_error, attempt) => timer(1000 * attempt) }),
                    tap({
                      next: ({ accountBalances, bankAccount, currency }) => {
                        const itemCurrency = currency ?? 'USD';
                        item.accountBalance = accountBalances?.[itemCurrency]?.accountBalance;
                        item.availableBalance = accountBalances?.[itemCurrency]?.availableBalance;
                        item.bankAccount = bankAccount ?? item.bankAccount;
                      },
                    }),
                    catchError((error) => {
                      balanceErrorsCount += 1;
                      ErrorUtils.catchError('getFinancialAccountBalancesWithAccountInfo error', error);
                      return of({});
                    }),
                  );
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => ({ financialAccountsResponse, balanceErrorsCount })),
              );
            }),
            switchMap(({ financialAccountsResponse, balanceErrorsCount }) => {
              const items = financialAccountsResponse.content ?? [];
              let customerErrorsCount = 0;
              return forkJoin(
                items.map((item) => {
                  if (!requestParams.skipAccountHolderDetails && item.accountHolderId && item.accountHolderType === 'CUSTOMER') {
                    return this.customerService.getCustomerById(item.accountHolderId).pipe(
                      tap({
                        next: (customer) => {
                          item.customer = customer;
                        },
                      }),
                      catchError((error) => {
                        customerErrorsCount += 1;
                        ErrorUtils.catchError('customerService.getCustomerById error', error);
                        return of({});
                      }),
                    );
                  } else {
                    return of(item);
                  }
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => {
                  if (balanceErrorsCount > 0) {
                    this.notificationService.displayError(
                      `We couldn't fetch balances for ${balanceErrorsCount} of your Financial Account${
                        balanceErrorsCount > 1 ? 's' : ''
                      }. Please try again later or contact your administrator.`,
                    );
                  }
                  if (customerErrorsCount > 0) {
                    this.notificationService.displayError(
                      `We couldn't fetch Customer Details for ${customerErrorsCount} of your Financial Account${
                        balanceErrorsCount > 1 ? 's' : ''
                      }. Please try again later or contact your administrator.`,
                    );
                  }
                  return mapFinancialAccountList(financialAccountsResponse);
                }),
              );
            }),
          ),
      ),
    );
  }

  public getRecipientFinancialAccounts({ recipientId, page, size }: RecipientFinancialAccountsRequest): Observable<FinancialAccountList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountListRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${recipientId}/financial-accounts`,
            { params },
          )
          .pipe(
            map((response) => mapFinancialAccountList(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public addEntityFinancialAccount(
    entityId: string,
    entityType: 'CUSTOMER' | 'RECIPIENT',
    { cardOrBankAccount, ...financialAccountData }: FinancialAccountCreateData,
  ): Observable<EntityFinancialAccountDetailsRaw> {
    const entityTypes = {
      CUSTOMER: 'customers',
      RECIPIENT: 'recipients',
    };
    const type = cardOrBankAccount === 'card' ? 'cards' : 'bank-accounts';
    const isInternal = financialAccountData.category === 'INTERNAL';
    const internalOrExternalPath = isInternal
      ? 'internal-financial-accounts'
      : `${entityTypes[entityType]}/${entityId}/financial-accounts/${type}`;

    const createData = isInternal
      ? { ...omit(financialAccountData, ['category', 'subtype']), accountHolderId: entityId, accountHolderType: entityType }
      : financialAccountData;

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<EntityFinancialAccountDetailsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/${internalOrExternalPath}`,
            createData,
            { context: new HttpContext().set(IS_EXTENDED_ERRORS, true) },
          )
          .pipe(
            map((financialAccount) => financialAccount),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public addRecipientPaymentMethod(
    recipientId: Recipient['id'],
    { cardOrBankAccount, ...financialAccountData }: FinancialAccountCreateData,
  ): Observable<FinancialAccountDetails> {
    const type = cardOrBankAccount === 'card' ? 'cards' : 'bank-accounts';

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<FinancialAccountDetails>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${recipientId}/financial-accounts/${type}`,
            financialAccountData,
          )
          .pipe(
            map((financialAccount) => mapFinancialAccountDetails(financialAccount)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getCustomerFinancialAccounts({ customerId, page, size }: CustomerFinancialAccountsRequest): Observable<FinancialAccountList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountListRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/customers/${customerId}/financial-accounts`,
            { params },
          )
          .pipe(
            switchMap((financialAccountsResponse) => {
              const items = financialAccountsResponse.content ?? [];
              return forkJoin(
                items.map((item) => {
                  return this.getFinancialAccountBalancesWithAccountInfo(item.id).pipe(
                    tap({
                      next: ({ accountBalances }) => {
                        item.accountBalance = accountBalances?.['USD']?.accountBalance;
                        item.availableBalance = accountBalances?.['USD']?.availableBalance;
                      },
                    }),
                    catchError((error) => {
                      ErrorUtils.catchError('getFinancialAccountBalancesWithAccountInfo error', error);
                      return of({});
                    }),
                  );
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => financialAccountsResponse),
              );
            }),
            map((response) => mapFinancialAccountList(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getFinancialAccountById(financialAccountId: string): Observable<FinancialAccountDetails> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountDetailsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccountId}`,
          )
          .pipe(
            map((financialAccount) => mapFinancialAccountDetails(financialAccount)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getFinancialAccountDetailsById(financialAccountId: string): Observable<FinancialAccountDetails> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountDetailsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccountId}/details`,
          )
          .pipe(
            map((financialAccount) => mapFinancialAccountDetails(financialAccount)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public updateFinancialAccount({
    financialAccountId,
    financialAccountData,
  }: {
    financialAccountId: string;
    financialAccountData: FinancialAccountUpdateData;
  }): Observable<FinancialAccountDetails> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update<FinancialAccountDetailsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccountId}`,
            {
              ...financialAccountData,
            },
          )
          .pipe(
            map((financialAccount) => mapFinancialAccountDetails(financialAccount)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public updateFinancialAccountState({
    financialAccountId,
    state,
    accountHolderType,
    accountHolderId,
  }: {
    financialAccountId: string;
    state: string;
    accountHolderType?: 'CUSTOMER' | 'RECIPIENT';
    accountHolderId?: string;
  }): Observable<boolean> {
    const accountHolder = accountHolderType ? `${accountHolderType.toLowerCase()}s/${accountHolderId}/` : '';

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/${accountHolder}financial-accounts/${financialAccountId}/${state}`,
          )
          .pipe(map(() => true)),
      ),
    );
  }

  /**
   * Updates the billing address for a financial account
   * API {{base-url}}/financialaccountflow/api/portal/business-accounts/{{ba-id}}/financial-accounts/{{fa-id}}
   *
   * @param {Object} params - The parameters object.
   * @param {string} params.financialAccountId - The ID of the financial account to update.
   * @param {Address} params.address - The new billing address for the financial account.
   * @returns {Observable<boolean>} - An observable that emits a boolean indicating whether the update was successful.
   */
  public updateFinancialAccountBillingAddress({
    financialAccountId,
    address,
  }: {
    financialAccountId: string;
    address: Address;
  }): Observable<boolean> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .update(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccountId}`, {
            billingAddress: address,
          })
          .pipe(map(() => true)),
      ),
    );
  }

  public deleteFinancialAccount({
    financialAccountId,
    accountHolderType,
    accountHolderId,
  }: {
    financialAccountId: string;
    accountHolderType?: 'CUSTOMER' | 'RECIPIENT';
    accountHolderId?: string;
  }): Observable<boolean> {
    const accountHolder = accountHolderType ? `${accountHolderType.toLowerCase()}s/${accountHolderId}/` : '';
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .delete(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/${accountHolder}financial-accounts/${financialAccountId}`,
          )
          .pipe(map(() => true)),
      ),
    );
  }

  public createFinancialAccount({
    cardOrBankAccount,
    ...financialAccountData
  }: FinancialAccountCreateData): Observable<FinancialAccountDetails> {
    const type = cardOrBankAccount === 'card' ? 'cards' : 'bank-accounts';

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<FinancialAccountDetailsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${type}`,
            financialAccountData,
          )
          .pipe(
            map((financialAccount) => mapFinancialAccountDetails(financialAccount)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }
  public createCustomerFinancialAccount(
    customerId: string,
    financialAccountData: FinancialAccountCreateData,
  ): Observable<FinancialAccountDetails> {
    const isInternal = financialAccountData.category === 'INTERNAL';
    const type = financialAccountData.cardOrBankAccount === 'card' ? 'cards' : 'bank-accounts';
    const internalOrExternalPath = isInternal ? 'internal-financial-accounts' : `customers/${customerId}/financial-accounts/${type}`;

    const createData = isInternal
      ? { ...financialAccountData, accountHolderId: customerId, accountHolderType: 'CUSTOMER' }
      : financialAccountData;

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<FinancialAccountDetailsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/${internalOrExternalPath}`,
            createData,
          )
          .pipe(
            map((financialAccount) => mapFinancialAccountDetails(financialAccount)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getCustomerFinancialAccountBalances(customerId: string): Observable<FinancialAccountBalances> {
    return this.backendService.get<FinancialAccountBalances>(
      `${environment.financialAccountFlow}/v1/financial-account-holders/${customerId}/account-balances`,
      { context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to fetch customer Financial Account balances.') },
    );
  }

  public businessAccountBalances(businessAccountId: string): Observable<FinancialAccountBalances> {
    return this.backendService.get<FinancialAccountBalances>(
      `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/account-balances`,
    );
  }

  public getFinancialAccountBalancesWithAccountInfo(
    financialAccountId: FinancialAccountDetails['id'],
  ): Observable<FinancialAccountDetailsWithBalances> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountDetailsWithBalancesRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccountId}?embed=balances`,
          )
          .pipe(
            map((response) => mapFinancialAccountBalances(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getFinancialAccountDetailsWithBalances(id: string): Observable<FinancialAccountDetails> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountDetailsWithBalancesRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${id}?embed=balances`,
            { context: new HttpContext().set(CUSTOM_ERROR_MESSAGE, 'Unable to fetch Financial Account details.') },
          )
          .pipe(
            tap({
              next: (financialAccountInfo) => {
                const { createdBy, updatedBy } = financialAccountInfo;
                this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
              },
            }),
            map((response) => mapFinancialAccountDetails(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  /**
   * Retrieves the financial account activities for the specified financial account ID and request parameters.
   * API {{base-url}}/financialaccountflow/api/portal/v1/business-accounts/{{ba-id}}/financial-accounts/{{fa-id}}/activities
   *
   * @param {FinancialAccountActivitiesRequest} requestParams - An object containing the financial account ID and request parameters.
   * @returns {Observable<FinancialAccountActivities>} - An observable that emits the financial account activities.
   */
  public getFinancialAccountActivities({
    financialAccountId,
    ...requestParams
  }: FinancialAccountActivitiesRequest): Observable<FinancialAccountActivities> {
    const params = financialAccountActivitiesRequestParams(requestParams);

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountActivitiesRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccountId}/activities`,
            { params },
          )
          .pipe(
            map((response) => mapFinancialAccountActivities(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public addCustomerPaymentMethod(
    customerId: Customer['id'],
    financialAccountData: FinancialAccountCreateData,
  ): Observable<FinancialAccountDetails> {
    const type = financialAccountData.cardOrBankAccount === 'card' ? 'cards' : 'bank-accounts';

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<FinancialAccountDetails>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/customers/${customerId}/financial-accounts/${type}`,
            financialAccountData,
          )
          .pipe(
            map((financialAccount) => financialAccount),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getFinancialAccountsWithAccountHolder({
    page,
    size,
    type,
    state,
    accountTypes,
    accountHolderTypes = [],
  }: FinancialAccountsRequest): Observable<FinancialAccountWithAccountHolderList> {
    const pageSize = size ?? constants.FINANCIAL_ACCOUNT_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('page', `${page ?? 0}`).set('size', `${pageSize}`);

    if (type) {
      params = params.append('type', type);
    }

    if (state) {
      params = params.append('state', isArray(state) ? state.join(',') : state);
    }

    if (accountTypes) {
      params = params.append('categories', accountTypes.join(','));
    }

    if (accountHolderTypes.length) {
      params = params.append('accountHolderTypes', accountHolderTypes.join(','));
    }

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountWithAccountHolderListRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts`,
            {
              params,
            },
          )
          .pipe(
            switchMap((financialAccountsResponse) => {
              if (type === 'CARD') {
                return of(financialAccountsResponse);
              }

              const items = financialAccountsResponse.content ?? [];
              return forkJoin(
                items.map((item) => {
                  return this.getFinancialAccountBalancesWithAccountInfo(item.id).pipe(
                    tap({
                      next: ({ accountBalances, bankAccount }) => {
                        item.accountBalance = accountBalances?.['USD']?.accountBalance;
                        item.availableBalance = accountBalances?.['USD']?.availableBalance;
                        item.bankAccount = bankAccount ?? item.bankAccount;
                      },
                    }),
                    catchError((error) => {
                      ErrorUtils.catchError('getFinancialAccountBalancesWithAccountInfo error', error);
                      return of({});
                    }),
                  );
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => financialAccountsResponse),
              );
            }),
            switchMap((financialAccountsResponse) => {
              const items = financialAccountsResponse.content ?? [];
              return forkJoin(
                items.map((item) => {
                  if (item.accountHolderId && item.accountHolderType === 'RECIPIENT') {
                    return this.recipientService.getRecipientById(item.accountHolderId).pipe(
                      tap({
                        next: (recipient) => {
                          item.recipient = recipient;
                        },
                      }),
                      catchError(() => of({})),
                    );
                  } else if (item.accountHolderId && item.accountHolderType === 'CUSTOMER') {
                    return this.customerService.getCustomerById(item.accountHolderId).pipe(
                      tap({
                        next: (customer) => {
                          item.customer = customer;
                        },
                      }),
                      catchError(() => of({})),
                    );
                  } else {
                    return of(item);
                  }
                }),
              ).pipe(
                defaultIfEmpty({}),
                map(() => {
                  return mapFinancialAccountListWithEntities(financialAccountsResponse);
                }),
              );
            }),
          ),
      ),
    );
  }

  createIntegratedBankAccount(params: IntegratedFinancialAccountCreateData): Observable<FinancialAccountDetailsRaw> {
    return this.backendService
      .post<FinancialAccountDetailsRaw>(`${environment.financialAccountFlow}/integrated-bank-accounts`, params)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public setFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_ACTIVITY_FILTER, filters);
    this.updateFilterParams(filters);
  }

  public getFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_ACTIVITY_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateFilterParams(filters);
    }
  }

  private updateFilterParams(filters: FilterValues): void {
    this.activeActivityFilters.next(filters);
  }

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }

  public getFinancialAccountStatements({
    financialAccountId,
    page,
    size,
  }: FinancialAccountStatementsRequest): Observable<FinancialAccountStatements> {
    const pageSize = size ?? constants.TABLE_ROWS;

    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('financialAccountId', financialAccountId)
      .set('size', pageSize);

    if (page) {
      params = params.append('page', page);
    }

    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountStatementsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-account-statements`,
            { params },
          )
          .pipe(
            map((response) => mapFinancialAccountStatements(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  public getFinancialAccountStatementDetails({
    financialAccountId,
    yearMonth,
  }: {
    financialAccountId: string;
    yearMonth: string;
  }): Observable<FinancialAccountStatementDetails> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .get<FinancialAccountStatementDetailsRaw>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccountId}/statements/${yearMonth}`,
          )
          .pipe(
            map((response) => ({
              ...response,
              statementStartDate: utcToZonedTime(new Date(response.statementStartDate), systemTimeZone),
              statementEndDate: utcToZonedTime(new Date(response.statementEndDate), systemTimeZone),
              accountNumber: `*** ${response.accountNumber.replace(/\*/gu, '')}`,
              accountActivities: response.accountActivities.map((item) => ({
                ...item,
                description: `${item.description ?? ' '}::${item.statementDescription ?? ' '}`,
              })),
            })),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }

  getEscrowFinancialAccountById(escrowFinancialAccountId: string) {
    const escrowBusinessAccountId = '00000000-0000-0000-0000-000000000000';
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('embed', 'balances');

    return this.backendService
      .get<FinancialAccountDetailsWithBalancesRaw>(
        `${environment.financialAccountFlow}/business-accounts/${escrowBusinessAccountId}/financial-accounts/${escrowFinancialAccountId}`,
        { params },
      )
      .pipe(
        tap({
          next: (financialAccountInfo) => {
            const { createdBy, updatedBy } = financialAccountInfo;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => mapFinancialAccountDetails(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getEscrowAccountBalances(): Observable<FinancialAccountDetailsWithBalances> {
    const escrowBusinessAccountId = '00000000-0000-0000-0000-000000000000';
    return this.store.select(fromAuth.selectBusinessAccountId).pipe(
      exhaustMap((businessAccountId) => {
        const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
          .set('page', 0)
          .set('size', 20)
          .set('accountRoles', 'ESCROW')
          .set('accountHolderId', businessAccountId);
        return this.backendService.get<FinancialAccountListRaw>(
          `${environment.financialAccountFlow}/business-accounts/${escrowBusinessAccountId}/financial-accounts`,
          { params },
        );
      }),
      exhaustMap((financialAccountsResponse: FinancialAccountListRaw) => {
        const escrowFinancialAccountId = financialAccountsResponse?.content?.[0]?.id;
        if (!escrowFinancialAccountId) {
          return of();
        }

        return this.getEscrowFinancialAccountById(escrowFinancialAccountId);
      }),
      map((response) => mapFinancialAccountBalances(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  verifyFinancialAccount(financialAccountId: string): Observable<VerificationRequestResponse> {
    const payload = {
      method: 'PRENOTE',
      financialAccountIds: [financialAccountId],
    };
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) =>
        this.backendService
          .post<VerificationRequestResponse>(
            `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts:verify`,
            payload,
          )
          .pipe(
            map((response) => response),
            catchError((errorRes) => throwError(() => errorRes)),
          ),
      ),
    );
  }
}
