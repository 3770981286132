import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, defaultIfEmpty, withLatestFrom, exhaustMap, tap } from 'rxjs/operators';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { concatLatestFrom } from '@ngrx/effects';
import { compact, flatMap, some, sortBy } from 'lodash-es';
import { Store } from '@ngrx/store';
import { v5 as uuidv5 } from 'uuid';
import { environment } from '@env';
import {
  TransactionListRequest,
  MultiLegTransactionList,
  MultiLegTransactionListRaw,
  MultiLegTransactionDetails,
  BusinessAccount,
  FinancialAccountSelectionList,
  FinancialAccountSelectionListRaw,
  FinancialAccountSelectionListRequest,
  FinancialAccountDetailsWithBalancesRaw,
  RecipientRaw,
  CustomerDetailRaw,
  MultiLegPaymentReason,
  MultiLegPaymentReasonRaw,
  MultiLegSolutionConfig,
  MultiLegEnabledSolutionPermissionsRaw,
  MultiLegEnabledSolutionPermissions,
  MultiLegSolutionConfigSolutionName,
  MultiLegEnabledSolutionVerifiedPermissionRaw,
  cardAccountSubtypes,
  CreateTransactionMltData,
  CreateTransactionSltData,
  CreateTransactionSchedulerData,
  MultiLegTransactionDetailsResponse,
  MultiLegTransactionDetailsRaw,
  MultiLegTransactionFinancialAccount,
  FinancialAccountSelectionListItem,
  DeliverySpeedOptionsResponse,
  DeliverySpeedOption,
  TransactionHistoryRequest,
  TransactionHistoryList,
  TransactionHistoryListResponse,
} from '@shared/models';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { AuditActions, fromAuth, transactionFormFeature } from '@shared/store';
import { BackendService } from '@shared/services/backend.service';
import { NotificationService, TeamMemberService } from '@shared/services';
import { IS_EXTENDED_ERRORS, SKIP_ERROR_NOTIFICATION } from '@shared/interceptors';
import { getProcessingPriorityIconName, getProcessingPriorityName, systemTimeZone } from '@shared/utils';
import { mapTransactionHistoryData, transactionsRequestParams } from './transaction-mapping-utils';
import {
  mapFinancialAccountHolderInfo,
  mapFinancialAccountInfo,
  mapFinancialAccountSelectionList,
  mapMultiLegTransactionDeatilsData,
  mapMultiLegTransactionListData,
  normalizeDeliveryOptions,
} from './multi-leg-transactions-mapping-utils';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { selectTeamMembersInfo } from '@shared/store/audit-store';

@Injectable({
  providedIn: 'root',
})
export class MultiLegTransactionsService {
  private readonly basePath = `${environment.transactionFlowEndpoint}`;

  private readonly apiService = inject(BackendService);

  private readonly teamMemberService = inject(TeamMemberService);

  private readonly notificationService = inject(NotificationService);

  private readonly store = inject(Store);

  getMultiLegTransactions(requestParams: TransactionListRequest): Observable<MultiLegTransactionList> {
    let params = transactionsRequestParams(requestParams);

    params = params.append('embed', 'fromFinancialAccount');
    params = params.append('embed', 'toFinancialAccount');

    return this.apiService.get<MultiLegTransactionListRaw>(`${this.basePath}/multi-leg-transactions`, { params }).pipe(
      map((transactionsData) => mapMultiLegTransactionListData(transactionsData)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getMultiLegTransactionById(multiLegTransactionId: MultiLegTransactionDetails['id']): Observable<MultiLegTransactionDetails> {
    return this.apiService
      .get<MultiLegTransactionDetailsResponse>(`${this.basePath}/multi-leg-transactions/${multiLegTransactionId}`, { observe: 'response' })
      .pipe(
        exhaustMap((transactionResponse) => {
          const { headers, body } = transactionResponse;
          return this.getPaymentReasons().pipe(
            map((paymentReasons) => ({
              ...body,
              paymentReasons,
              transactionETag: headers.get('ETag')?.replace(/"/g, ''),
            })),
          );
        }),
        switchMap((transactionData) => {
          this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([transactionData.createdBy, transactionData.updatedBy]) }));

          const zonedDate = utcToZonedTime(transactionData.createdAt, systemTimeZone);
          const formattedZonedDate = format(zonedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

          const estimatedDeliveryDate$ = this.getDeliverySpeedOptions({
            debitFinancialAccountId: transactionData.debits[0].financialAccountId,
            creditFinancialAccountId: transactionData.credits[0].financialAccountId,
            dateTime: formattedZonedDate,
          });

          const debits$ = transactionData.debits.map((debitLeg) => {
            return this.getFinancialAccountAccountHolder(debitLeg.financialAccount).pipe(
              switchMap((financialAccount) => {
                const paymentReason = transactionData.paymentReasons.find((item) => item.value === debitLeg.paymentReasonId)?.label;

                return of({
                  ...debitLeg,
                  paymentReason,
                  processingPriority: getProcessingPriorityName(debitLeg.settlementPriority),
                  processingPriorityIcon: getProcessingPriorityIconName(debitLeg.settlementPriority),
                  financialAccount: mapFinancialAccountInfo(financialAccount),
                });
              }),
            );
          });

          const credits$ = transactionData.credits.map((creditLeg) => {
            return this.getFinancialAccountAccountHolder(creditLeg.financialAccount).pipe(
              switchMap((financialAccount) => {
                const paymentReason = transactionData.paymentReasons.find((item) => item.value === creditLeg.paymentReasonId)?.label;

                return of({
                  ...creditLeg,
                  paymentReason,
                  processingPriority: getProcessingPriorityName(creditLeg.settlementPriority),
                  processingPriorityIcon: getProcessingPriorityIconName(creditLeg.settlementPriority),
                  financialAccount: mapFinancialAccountInfo(financialAccount),
                });
              }),
            );
          });

          return forkJoin([forkJoin(debits$), forkJoin(credits$), estimatedDeliveryDate$]).pipe(
            map(([debits, credits, deliverySpeedOptions]) => {
              return {
                ...transactionData,
                debits: [...debits],
                credits: [...credits],
                deliverySpeedOptions,
              };
            }),
          );
        }),
        map((transactionData) => mapMultiLegTransactionDeatilsData(transactionData)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getFinancialAccounts(
    businessAccountId: BusinessAccount['id'],
    {
      page,
      size,
      sortParams,
      searchString,
      categories,
      activeFilters,
      type,
      financialAccountType,
      currency,
      recipientAccountHolderId,
    }: FinancialAccountSelectionListRequest,
  ): Observable<FinancialAccountSelectionList> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('page', `${page ?? 0}`)
      .set('size', `${size ?? constants.FINANCIAL_ACCOUNT_ROWS}`);

    if (searchString) {
      params = params.append('name', searchString);
    }

    if (categories) {
      params = params.append('categories', categories.join(','));
    }

    if (type === 'from' && !financialAccountType) {
      params = params.append('type', 'BANK');
    }

    if (financialAccountType) {
      params = params.append('type', financialAccountType);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        if (activeFilters[key] === undefined) {
          return;
        }

        if (key === 'accountType') {
          params = params.delete('type');
          params = params.append('type', activeFilters[key]!);
          return;
        }

        params = params.append(key, activeFilters[key]!);
      });
    }

    params = params.append('currency', 'USD');

    if (recipientAccountHolderId && activeFilters && activeFilters['accountHolderTypes'] === 'RECIPIENT') {
      params = params.append('recipientAccountHolderId', recipientAccountHolderId);
    }

    return this.apiService
      .get<FinancialAccountSelectionListRaw>(
        `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts`,
        {
          params,
        },
      )
      .pipe(
        withLatestFrom(
          this.store.select(transactionFormFeature.selectFromAccount),
          this.store.select(transactionFormFeature.selectToAccount),
        ),
        switchMap(([financialAccountsResponse, fromAccount]) => {
          const items = financialAccountsResponse.content ?? [];
          return forkJoin(
            items.map((financialAccount) => {
              /*
               Disable Financial Accounts not allowed for selection
              */

              /* Selected as From Account */
              const isSelectedAsFromAccount = type === 'to' && fromAccount ? fromAccount.id === financialAccount.id : undefined;

              /* External Card as From Account Not Supported */
              const isFromExternalCardAccount = type === 'from' && cardAccountSubtypes.includes(financialAccount.subtype);

              /* Integrated card Not Supported */
              const isIntegratedCardAccount =
                financialAccount.category === 'INTEGRATED' && cardAccountSubtypes.includes(financialAccount.subtype);

              /* Tokenized Account Not Supported */
              const isTokenized = financialAccount.subtype === 'TOKENIZED';

              /* Integrated Bank -> Integrated Bank [different parent] */
              const isIntegratedBankToIntegratedBankNotSameParent =
                type === 'to' && fromAccount?.category === 'INTEGRATED'
                  ? financialAccount.category === 'INTEGRATED' && fromAccount.parentId !== financialAccount.parentId
                  : undefined;

              /* Integrated Bank -> Internal [different parent or not child] */
              const isIntegratedBankToInternalNotSameParentOrNotChild =
                type === 'to' && fromAccount?.category === 'INTEGRATED'
                  ? financialAccount.category === 'INTERNAL' &&
                    !(
                      fromAccount.parentId === financialAccount.parentId ||
                      fromAccount.parentId === financialAccount.id ||
                      fromAccount.id === financialAccount.parentId
                    )
                  : undefined;

              /* Internal -> Integrated Bank [different parent or not child] */
              const isInternalToIntegratedBankNotSameParentOrNotChild =
                type === 'to' && fromAccount?.category === 'INTERNAL'
                  ? financialAccount.category === 'INTEGRATED' &&
                    !(
                      fromAccount.parentId === financialAccount.parentId ||
                      fromAccount.parentId === financialAccount.id ||
                      fromAccount.id === financialAccount.parentId
                    )
                  : undefined;

              /* Internal -> Internal [different parent or not child] */
              const isInternalToInternalNotSameParentOrNotChild =
                type === 'to' && fromAccount?.category === 'INTERNAL'
                  ? financialAccount.category === 'INTERNAL' &&
                    !(
                      fromAccount.parentId === financialAccount.parentId ||
                      fromAccount.parentId === financialAccount.id ||
                      fromAccount.id === financialAccount.parentId
                    )
                  : undefined;

              /* Customer Recipient -> BA */
              const isCustomerRecipientToBA =
                type === 'to' &&
                fromAccount?.accountHolderType === 'RECIPIENT' &&
                fromAccount?.accountHolderId &&
                !financialAccount?.accountHolderId;

              const isCurrencyNotMatch = currency ? currency !== financialAccount.currency : undefined;

              const isSelectionDisabled = some([
                isSelectedAsFromAccount,
                isFromExternalCardAccount,
                isIntegratedCardAccount,
                isTokenized,
                isIntegratedBankToIntegratedBankNotSameParent,
                isIntegratedBankToInternalNotSameParentOrNotChild,
                isInternalToIntegratedBankNotSameParentOrNotChild,
                isInternalToInternalNotSameParentOrNotChild,
                isCurrencyNotMatch,
                isCustomerRecipientToBA,
              ]);

              if (financialAccount.category === 'EXTERNAL') {
                return of({ ...financialAccount, isSelectionDisabled });
              }
              return this.apiService
                .get<FinancialAccountDetailsWithBalancesRaw>(
                  `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${financialAccount.id}?embed=balances`,
                )
                .pipe(
                  map((financialAccountDetails) => {
                    const financialAccountUpdated = { ...financialAccount };
                    financialAccountUpdated.financialAccountDetails = financialAccountDetails;
                    financialAccountUpdated.isSelectionDisabled = isSelectionDisabled;
                    return financialAccountUpdated;
                  }),
                );
            }),
          ).pipe(
            map((financialAccountsWithDetails) => {
              return { ...financialAccountsResponse, content: [...financialAccountsWithDetails] };
            }),
            defaultIfEmpty(financialAccountsResponse),
          );
        }),
        withLatestFrom(
          this.store.select(fromAuth.selectActiveBusinessAccountDetails),
          this.store.select(transactionFormFeature.selectFromAccount),
        ),
        switchMap(([financialAccountsResponse, activeBusinessAccount, fromAccount]) => {
          const items = financialAccountsResponse.content ?? [];
          return forkJoin(
            items.map((item) => {
              if (item.accountHolderType === 'RECIPIENT' && fromAccount?.accountHolderType === 'RECIPIENT') {
                return of({ ...item, isSelectionDisabled: true });
              } else if (item.accountHolderId && item.accountHolderType === 'RECIPIENT') {
                return this.apiService
                  .get<RecipientRaw>(
                    `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${item.accountHolderId}`,
                  )
                  .pipe(
                    map((recipient) => {
                      const financialAccount = { ...item };

                      financialAccount.recipient = recipient;
                      financialAccount.isSelectionDisabled =
                        item.isSelectionDisabled ||
                        (type === 'to' &&
                          !!recipientAccountHolderId &&
                          (recipient.accountHolderId
                            ? recipient.accountHolderId !== recipientAccountHolderId
                            : recipient.businessAccountId !== recipientAccountHolderId));
                      return financialAccount;
                    }),
                    catchError(() => {
                      // Disable Financial Accounts when not able to fetch Recipient details
                      return of({ ...item, itemError: 'We are unable to fetch Recipient details. Please contact administrator.' });
                    }),
                  );
              } else if (item.accountHolderId && item.accountHolderType === 'CUSTOMER') {
                return this.apiService
                  .get<CustomerDetailRaw>(`${environment.accountFlowService}/v2/customers/${item.accountHolderId}`, {
                    context: new HttpContext().set(SKIP_ERROR_NOTIFICATION, true),
                  })
                  .pipe(
                    map((customer) => {
                      const financialAccount = { ...item };
                      financialAccount.customer = customer;
                      financialAccount.isSelectionDisabled =
                        item.isSelectionDisabled ||
                        (type === 'to' &&
                          fromAccount?.accountHolderType === 'RECIPIENT' &&
                          !!recipientAccountHolderId &&
                          customer.id !== recipientAccountHolderId);
                      return financialAccount;
                    }),
                    catchError(() => {
                      // Disable Financial Accounts when not able to fetch Customer details
                      return of({ ...item, itemError: 'We are unable to fetch Customer details. Please contact administrator.' });
                    }),
                  );
              } else if (!item.accountHolderId && item.businessAccountId === activeBusinessAccount.id) {
                return of({ ...item, businessAccount: activeBusinessAccount });
              } else {
                return of(item);
              }
            }),
          ).pipe(
            map((financialAccountsWithAccountHolder) => {
              return { ...financialAccountsResponse, content: [...financialAccountsWithAccountHolder] };
            }),
            defaultIfEmpty(financialAccountsResponse),
          );
        }),
        map((financialData) => mapFinancialAccountSelectionList(financialData)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getPaymentReasons(): Observable<MultiLegPaymentReason[]> {
    return this.apiService.get<MultiLegPaymentReasonRaw[]>(`${environment.transactionFlowEndpoint}/payment-reasons`).pipe(
      map((response = []) => {
        return sortBy(response, 'reason')
          .filter(({ state, types }) => state === 'ACTIVE' && types?.length)
          .map((reasons) => {
            const { id, reason, allowedSolutions, types } = reasons;
            return { label: reason, value: id, allowedSolutions, types: types! };
          });
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  getEnabledSolutions(): Observable<MultiLegEnabledSolutionPermissions> {
    return this.apiService
      .get<MultiLegEnabledSolutionPermissionsRaw>(`${environment.accountPermissionEndpoint}/permissions/enabled-solutions`)

      .pipe(
        map((response) => {
          const { enabledSolutions = [] } = response ?? {};
          const verifiedPermissions: MultiLegEnabledSolutionVerifiedPermissionRaw[] = flatMap(enabledSolutions, 'verifiedPermissions');

          const permissions: MultiLegEnabledSolutionPermissions = {
            ach: false,
            'push-to-card': false,
            transfer: false,
            wire: false,
          };

          verifiedPermissions.forEach((item) => {
            const actionNameSplitted = item.actionName.split('.');
            const service = actionNameSplitted[0];
            const solution = actionNameSplitted[1];
            const operation = actionNameSplitted[2];
            if (service !== 'transactions' || operation !== 'create' || !solution) {
              return;
            }

            if (['wire', 'transfer', 'ach', 'push-to-card'].includes(solution)) {
              permissions[solution as MultiLegSolutionConfigSolutionName] = item.accessGranted;
            }
          });
          return permissions;
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getSolutionConfigs(solutionNames: string[]): Observable<MultiLegSolutionConfig[]> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('solutionNames', solutionNames.join(','));
    return this.apiService.get<MultiLegSolutionConfig[]>(`${environment.transactionFlowEndpoint}/solutions/quick-info`, { params }).pipe(
      catchError((errorRes) => {
        return throwError(() => errorRes);
      }),
    );
  }

  createMltTransaction(data: CreateTransactionMltData, idempotencyTimeConstraint: string | null): Observable<string> {
    const transactionDataString = JSON.stringify({ ...data, idempotencyTimeConstraint });
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey);

    return this.apiService
      .post<{ id: string }>(`${this.basePath}/multi-leg-transactions`, data, {
        headers,
        context: new HttpContext().set(IS_EXTENDED_ERRORS, true),
      })
      .pipe(map((response) => response.id));
  }

  updateMltTransaction(transactionId: string, transactionETag: string, data: CreateTransactionMltData) {
    const transactionDataString = JSON.stringify(data);
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey).set('If-Match', transactionETag);

    return this.apiService
      .update(`${this.basePath}/multi-leg-transactions/${transactionId}`, data, {
        headers,
        context: new HttpContext().set(IS_EXTENDED_ERRORS, true),
      })
      .pipe(
        defaultIfEmpty(() => of()),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  updateSltTransaction(transactionId: string, businessAccountId: BusinessAccount['id'], editData: CreateTransactionSltData) {
    const transactionDataString = transactionId + JSON.stringify(editData);
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey);

    return this.apiService
      .update<{ id: string }>(`${this.basePath}/business-accounts/${businessAccountId}/transactions/${transactionId}`, editData, {
        headers,
        context: new HttpContext().set(IS_EXTENDED_ERRORS, true),
      })
      .pipe(
        defaultIfEmpty(() => of()),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  createSltTransaction(
    businessAccountId: string,
    data: CreateTransactionSltData,
    idempotencyTimeConstraint: string | null,
  ): Observable<string> {
    const transactionDataString = JSON.stringify({ ...data, idempotencyTimeConstraint });
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey);

    return this.apiService
      .post<{ id: string }>(`${this.basePath}/business-accounts/${businessAccountId}/transactions`, data, {
        headers,
        context: new HttpContext().set(IS_EXTENDED_ERRORS, true),
      })
      .pipe(map((response) => response.id));
  }

  createScheduledMltTransaction(
    schedulerData: CreateTransactionSchedulerData,
    data: CreateTransactionMltData,
    idempotencyTimeConstraint: string | null,
  ): Observable<string> {
    const transactionDataString = JSON.stringify({ ...data, idempotencyTimeConstraint });
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey);

    return this.apiService
      .post<string>(
        `${environment.transactionV2Endpoint}/scheduled-transactions`,
        { multiLegSpec: { ...data }, ...schedulerData, timeZone: 'UTC', status: 'ACTIVE' },
        { headers, context: new HttpContext().set(IS_EXTENDED_ERRORS, true) },
      )
      .pipe(map((response) => response));
  }

  createScheduledSltTransaction(
    businessAccountId: BusinessAccount['id'],
    schedulerData: CreateTransactionSchedulerData,
    data: CreateTransactionSltData,
    idempotencyTimeConstraint: string | null,
  ): Observable<string> {
    const transactionDataString = JSON.stringify({ ...data, idempotencyTimeConstraint });
    const idempotencyKey = `idemp-${uuidv5(transactionDataString, constants.UUID_NAMESPACE)}`;
    const headers = new HttpHeaders().set('Idempotency-Key', idempotencyKey);

    return this.apiService
      .post<{ id: string }>(
        `${this.basePath}/business-accounts/${businessAccountId}/scheduled-transactions`,
        { ...data, ...schedulerData },
        { headers, context: new HttpContext().set(IS_EXTENDED_ERRORS, true) },
      )
      .pipe(map((response) => response.id));
  }

  cancelMltTransaction(transactionId: string, financialAccountId?: string): Observable<MultiLegTransactionDetailsRaw> {
    return this.apiService
      .post<MultiLegTransactionDetailsRaw>(
        `${this.basePath}/multi-leg-transactions/${transactionId}/cancel`,
        {
          recoveryFinancialAccountId: financialAccountId,
        },
        {
          context: new HttpContext().set(IS_EXTENDED_ERRORS, true),
        },
      )
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  getFinancialAccountAccountHolder(
    financialAccountResponse: MultiLegTransactionFinancialAccount,
  ): Observable<MultiLegTransactionFinancialAccount> {
    const { accountHolderId, accountHolderType, businessAccountId } = financialAccountResponse;
    if (accountHolderId && accountHolderType === 'RECIPIENT') {
      return this.apiService
        .get<RecipientRaw>(`${environment.financialAccountFlow}/business-accounts/${businessAccountId}/recipients/${accountHolderId}`)
        .pipe(
          map((recipient) => mapFinancialAccountHolderInfo({ ...financialAccountResponse, recipient })),
          catchError(() => of(financialAccountResponse)),
        );
    } else if (accountHolderId && accountHolderType === 'CUSTOMER') {
      return this.apiService
        .get<CustomerDetailRaw>(`${environment.accountFlowService}/v2/customers/${accountHolderId}`, {
          context: new HttpContext().set(SKIP_ERROR_NOTIFICATION, true),
        })
        .pipe(
          map((customer) => mapFinancialAccountHolderInfo({ ...financialAccountResponse, customer })),
          catchError(() => of(financialAccountResponse)),
        );
    } else if (!accountHolderId) {
      return of(true).pipe(
        concatLatestFrom(() => this.store.select(fromAuth.selectActiveBusinessAccountDetails)),
        map(([, activeBusinessAccount]) => {
          if (businessAccountId === activeBusinessAccount.id) {
            return mapFinancialAccountHolderInfo({ ...financialAccountResponse, businessAccount: activeBusinessAccount });
          }

          return financialAccountResponse;
        }),
      );
    } else {
      return of(financialAccountResponse);
    }
  }

  getDefaultBusinessAccountFinancialAccount(businessAccountId: string): Observable<FinancialAccountSelectionListItem> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('page', '0')
      .set('size', '1')
      .set('type', 'BANK')
      .set('state', 'ACTIVE')
      .set('accountHolderTypes', 'BUSINESS_ACCOUNT')
      .set('INTERNAL', 'INTERNAL')
      .set('categories', 'INTERNAL')
      .set('defaultFlag', 'true')
      .set('sort', 'createdAt,ASC');

    return this.apiService
      .get<FinancialAccountSelectionListRaw>(
        `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts`,
        {
          params,
        },
      )
      .pipe(
        withLatestFrom(this.store.select(fromAuth.selectActiveBusinessAccountDetails)),
        map(([result, activeBusinessAccount]) => {
          const items = result.content?.map((financialAccount) => ({ ...financialAccount, businessAccount: activeBusinessAccount })) ?? [];
          return mapFinancialAccountSelectionList({ ...result, content: [...items] }).items[0] ?? null;
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  getDeliverySpeedOptions({
    debitFinancialAccountId,
    creditFinancialAccountId,
    amount,
    currency,
    dateTime,
  }: {
    debitFinancialAccountId: string;
    creditFinancialAccountId: string;
    amount?: string;
    currency?: string;
    dateTime?: string;
  }): Observable<DeliverySpeedOption[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('debitFinancialAccountId', debitFinancialAccountId)
      .set('creditFinancialAccountId', creditFinancialAccountId);

    if (amount) {
      params = params.append('amount', amount);
    }

    if (currency) {
      params = params.append('currency', currency);
    }

    if (dateTime) {
      params = params.append('startDateTime', dateTime);
    }

    return this.apiService.get<DeliverySpeedOptionsResponse>(`${environment.transactionFlowEndpoint}/transaction-options`, { params }).pipe(
      map((response) => {
        return normalizeDeliveryOptions(response, dateTime);
      }),
    );
  }

  public getMultilegTransactionHistory({ transactionId, page, size }: TransactionHistoryRequest): Observable<TransactionHistoryList> {
    const pageSize = size ?? constants.TABLE_ROWS;

    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('page', `${page ?? 0}`)
      .set('pageSize', `${pageSize}`)
      .set('transactionId', transactionId);

    return this.apiService
      .get<TransactionHistoryListResponse>(`${this.basePath}/multi-leg-transactions/${transactionId}/histories`, { params })
      .pipe(
        tap({
          next: (response) => {
            const { content = [] } = response || {};
            content.forEach((item) => {
              this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([item.createdBy]) }));
            });
          },
        }),
        concatLatestFrom(() => [this.store.select(selectTeamMembersInfo)]),
        exhaustMap(([transactionHistoryResponse, teamMemberInfo]) => of({ transactionHistoryResponse, teamMemberInfo })),
        map(({ transactionHistoryResponse, teamMemberInfo }) => mapTransactionHistoryData(transactionHistoryResponse, teamMemberInfo)),
      );
  }
}
