import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/effects';
import { RktFormFieldConfig, formInput, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { MultiLegTransactionAchDetailsModel, FormModel } from '@shared/models';
import { TransactionFormActions, transactionFormFeature } from '@shared/store';
import { achPullEntryTypeCodes, achPushEntryTypeCodes } from '@shared/services';

@Component({
  selector: 'app-create-transaction-ach-details',
  templateUrl: './create-transaction-ach-details.component.html',
})
export class CreateTransactionAchDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() type: 'from' | 'to' = 'from';

  secCodeForm = new FormGroup({});

  individualIdForm = new FormGroup({});

  checkSerialNumberForm = new FormGroup({});

  terminalCityForm = new FormGroup({});

  terminalStateForm = new FormGroup({});

  rkorACHEntryTypeOptions$ = new BehaviorSubject<{ label: string; value: string }[]>([]);

  secCodeFormFields: RktFormFieldConfig[] = [
    formSelect({
      key: 'rkorACHEntryType',
      label: '',
      props: {
        required: true,
        options: this.rkorACHEntryTypeOptions$.asObservable(),
      },
      expressions: {
        'props.disabled': 'model?.isDisabled',
      },
    }),
  ];

  individualIdFormFields: RktFormFieldConfig[] = [
    formInput({
      key: 'rkorACHIndividualId',
      label: '',
      expressions: {
        'props.disabled': 'model?.isDisabled',
        'props.maxLength': 'model?.rkorACHEntryType === "CIE" ? 22 : 15',
        required: "model?.rkorACHEntryType ? ['CIE', 'PPD', 'TEL'].includes(model?.rkorACHEntryType) : false",
      },
    }),
  ];

  checkSerialNumberFormFields: RktFormFieldConfig[] = [
    formInput({
      key: 'rkorACHCheckSerialNumber',
      label: '',
      expressions: {
        'props.disabled': 'model?.isDisabled',
        'props.maxLength': 'model?.rkorACHEntryType && ["POP"].includes(model?.rkorACHEntryType) ? 9 : 15',
        required: "model?.rkorACHEntryType ? ['ARC', 'BOC', 'POP', 'RCK'].includes(model.rkorACHEntryType) : false",
      },
    }),
  ];

  terminalCityFormFields: RktFormFieldConfig[] = [
    formInput({
      key: 'rkorACHTerminalCity',
      label: '',
      props: { maxLength: 4 },
      expressions: { 'props.disabled': 'model?.isDisabled', required: "model?.rkorACHEntryType === 'POP'" },
    }),
  ];

  terminalStateFormFields: RktFormFieldConfig[] = [
    formInput({
      key: 'rkorACHTerminalState',
      label: '',
      props: { maxLength: 4 },
      expressions: { 'props.disabled': 'model?.isDisabled', required: "model?.rkorACHEntryType === 'POP'" },
    }),
  ];

  achDetailsModel: FormModel<MultiLegTransactionAchDetailsModel> = {
    rkorACHEntryType: undefined,
    rkorACHIndividualId: undefined,
    rkorACHCheckSerialNumber: undefined,
    rkorACHTerminalCity: undefined,
    rkorACHTerminalState: undefined,
    isDisabled: false,
    bypassDebitAuthorization: undefined,
  };

  private destroy$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    this.store
      .select(transactionFormFeature.selectTransactionType)
      .pipe(
        concatLatestFrom(() => [
          this.store.select(transactionFormFeature.selectFromAccountAchDetails),
          this.store.select(transactionFormFeature.selectFormAction),
          this.store.select(transactionFormFeature.selectFromAccount),
        ]),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([transactionType, fromAchDetails, formAction, fromAccount]) => {
          switch (this.type) {
            case 'from':
              if (fromAccount?.category === 'EXTERNAL') {
                this.rkorACHEntryTypeOptions$.next(achPullEntryTypeCodes);
              } else {
                this.rkorACHEntryTypeOptions$.next(achPushEntryTypeCodes);
              }

              break;

            case 'to':
              this.rkorACHEntryTypeOptions$.next(achPushEntryTypeCodes);
              break;

            default:
              break;
          }

          if (this.type === 'from' && formAction === 'EDIT_SCHEDULED_SLT' && fromAchDetails) {
            this.achDetailsModel = {
              ...this.achDetailsModel,
              ...fromAchDetails,
              isDisabled: false,
            };
          } else if (this.type === 'from' && formAction === 'EDIT_SLT' && fromAchDetails) {
            this.achDetailsModel = {
              ...this.achDetailsModel,
              ...fromAchDetails,
              isDisabled: true,
            };
          } else {
            this.achDetailsModel = {
              ...this.achDetailsModel,
              rkorACHEntryType: transactionType === 'MLT' ? 'CCD' : undefined,
              isDisabled: transactionType === 'MLT',
            };
          }

          this.onAchDetailsChange();
        },
      });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAchDetailsChange() {
    this.achDetailsModel = { ...this.achDetailsModel };

    if (this.type === 'from') {
      this.store.dispatch(TransactionFormActions.setMoveFromAchDetails({ achDetails: { ...this.achDetailsModel } }));
    } else if (this.type === 'to') {
      this.store.dispatch(TransactionFormActions.setMoveToAchDetails({ achDetails: { ...this.achDetailsModel } }));
    }
  }

  onOfflineDebitAuthorizationToggle(): void {
    this.achDetailsModel = {
      ...this.achDetailsModel,
      bypassDebitAuthorization: this.achDetailsModel.bypassDebitAuthorization ? undefined : true,
    };
    this.onAchDetailsChange();
  }
}
