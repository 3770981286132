import { InjectionToken } from '@angular/core';
import { BuildEnvironment } from '@shared/models';

export interface AppConfig {
  name: BuildEnvironment;
  apiHost: string;
  companyDomain: string;
  supersetDomain: string;
  firebase: { [key: string]: string };
  production: boolean;
}

export const APP_ENV_CONFIG = new InjectionToken<AppConfig>('Application Environment Config');

export const getEnvironment = (): AppConfig => {
  switch (window.location.host) {
    case 'portal-internal.rocketkor.net':
    case 'portal.rocketkor.net':
      return {
        name: 'production',
        production: true,
        apiHost: 'https://api.rocketkor.net',
        companyDomain: 'https://portal.rocketkor.net',
        supersetDomain: 'https://insights.rocketkor.net',
        firebase: {
          apiKey: 'AIzaSyCsP8Y-00FmGPqMPd7oDozyN6pToQX4ekk',
          authDomain: 'prod-rocketkor.firebaseapp.com',
          projectId: 'prod-rocketkor',
          storageBucket: 'prod-rocketkor.appspot.com',
          messagingSenderId: '681232479177',
          appId: '1:681232479177:web:e8066e71b675edf1646e18',
          measurementId: 'G-2KXHTN4G99',
        },
      };
    case 'portal-internal.sandbox.rocketkor.net':
    case 'portal.sandbox.rocketkor.net':
      return {
        name: 'sandbox',
        production: true,
        apiHost: 'https://api.sandbox.rocketkor.net',
        companyDomain: 'https://portal.sandbox.rocketkor.net',
        supersetDomain: 'https://insights.sandbox.rocketkor.net',
        firebase: {
          apiKey: 'AIzaSyDRVI7IUPWfqplVP_8tp_Ub55GauQ41Jgs',
          authDomain: 'cs-rocketkor.firebaseapp.com',
          projectId: 'cs-rocketkor',
          storageBucket: 'cs-rocketkor.appspot.com',
          messagingSenderId: '860477795296',
          appId: '1:860477795296:web:314d1c2b297636158a1985',
          measurementId: 'G-D3LVGG0ME9',
        },
      };
    case 'portal-internal.stg.rocketkor-nonprod.net':
    case 'portal.stg.rocketkor-nonprod.net':
      return {
        name: 'staging',
        production: false,
        apiHost: 'https://api.stg.rocketkor-nonprod.net',
        companyDomain: 'https://portal.stg.rocketkor-nonprod.net',
        supersetDomain: 'https://insights.stg.rocketkor-nonprod.net',
        firebase: {
          apiKey: 'AIzaSyB0PSMkUxqnZiGoyOkAhRtiH17g83qd0DI',
          authDomain: 'stg-rocketkor.firebaseapp.com',
          projectId: 'stg-rocketkor',
          storageBucket: 'stg-rocketkor.appspot.com',
          messagingSenderId: '80281875097',
          appId: '1:80281875097:web:47ac079a758d0beb4a7e08',
          measurementId: 'G-BVTGE3GGSG',
        },
      };
    case 'ambassadoredge.qa.rocketkor-nonprod.net':
    case 'portal.qa.rocketkor-nonprod.net':
      return {
        name: 'qa',
        production: false,
        apiHost: 'https://api.qa.rocketkor-nonprod.net',
        companyDomain: 'https://portal.qa.rocketkor-nonprod.net',
        supersetDomain: 'https://insights.qa.rocketkor-nonprod.net',
        firebase: {
          apiKey: 'AIzaSyCtIU_yvJRCCIYkCsJSbS4pNO8TamlP50U',
          authDomain: 'qa-rocketkor.firebaseapp.com',
          projectId: 'qa-rocketkor',
          storageBucket: 'qa-rocketkor.appspot.com',
          messagingSenderId: '941992412175',
          appId: '1:941992412175:web:80b541e4dfe1154d800f7a',
          measurementId: 'G-L5HN80PTH3',
        },
      };
    case 'ambassadoredge.dev.rocketkor-nonprod.net':
    case 'portal.demo.rocketkor-nonprod.net':
    case 'portal.dev.rocketkor-nonprod.net':
    default:
      return {
        name: 'develop',
        production: false,
        apiHost: 'https://api.dev.rocketkor-nonprod.net',
        companyDomain: 'https://portal.dev.rocketkor-nonprod.net',
        supersetDomain: 'https://insights.dev.rocketkor-nonprod.net',
        firebase: {
          apiKey: 'AIzaSyAgLrhRS6xRB6yKr_Qp5Kc3J1UBe7c-ffM',
          authDomain: 'dev-rocketkor.firebaseapp.com',
          projectId: 'dev-rocketkor',
          storageBucket: 'dev-rocketkor.appspot.com',
          messagingSenderId: '488449785816',
          appId: '1:488449785816:web:e462e770b90cba490ffcad',
        },
      };
  }
};
