import { AbstractControl, ValidationErrors } from '@angular/forms';
import { formCheckboxGroup, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { LinxBeneficiaryProfileType } from '@shared/constants';
import { compact } from 'lodash-es';

export const linxBeneficiaryProfileFields: RktFormFieldConfig[] = [
  {
    fieldGroup: [
      formCheckboxGroup({
        key: 'personalInfo',
        label: 'Personal Information',
        className: 'linx-checkbox-enhance-fields',
        props: {
          options: [
            { value: 'firstName', label: 'First name' },
            { value: 'lastName', label: 'Last name' },
            { value: 'middleName', label: 'Middle name' },
            { value: 'phoneNumber', label: 'Phone number' },
            { value: 'email', label: 'Email' },
            { value: 'sex', label: 'Sex' },
            { value: 'socialId', label: 'SSN' },
            { value: 'socialIdCountryCode', label: 'SSN Country' },
            { value: 'dateOfBirth', label: 'Date of Birth' },
            { value: 'cityOfBirth', label: 'City of Birth' },
            { value: 'countryOfBirthCode', label: 'Country of Birth' },
            { value: 'citizenshipCountryCode', label: 'Citizenship Country' },
            { value: 'jobTitle', label: 'Job title' },
            { value: 'percentageOwned', label: 'Percentage of Busines Owned' },
            { value: 'controller', label: 'Controller' },
          ],
        },
      }),
      formCheckboxGroup({
        key: 'addresses',
        label: 'Address Options',
        className: 'linx-checkbox-enhance-fields',
        props: {
          options: [
            { value: 'PHYSICAL', label: 'Physical' },
            { value: 'MAILING', label: 'Mailing' },
            { value: 'SHIPPING', label: 'Shipping' },
          ],
        },
      }),
    ],
    className: 'linx-checkbox-group',
    validators: {
      required: (control: AbstractControl): ValidationErrors | null => {
        const isExistingBeneficiary = control.value.beneficiaryType === LinxBeneficiaryProfileType.Existing;
        if (isExistingBeneficiary) {
          const nonEmpty: string[] = [];
          const controlArray = compact(
            Object.keys(control.value || {})
              .filter((key) => ['personalInfo', 'addresses', 'documentTypes'].includes(key))
              .map((key) => control.value[key]),
          );
          controlArray.forEach((item) => {
            const selectedOptions = Object.keys(item).filter((el) => item[el]);
            nonEmpty.push(...selectedOptions);
          });
          return nonEmpty.length ? { required: { message: 'error' } } : null;
        }
        return { required: { message: 'error' } };
      },
    },
  },
];
