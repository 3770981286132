import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Beneficiary } from '@shared/models';

export type BeneficiaryDetailsAction = 'DELETE' | 'SUSPEND' | 'UNSUSPEND';
@Component({
  selector: 'app-beneficiary-details-header',
  templateUrl: './beneficiary-details-header.component.html',
})
export class BeneficiaryDetailsHeaderComponent {
  @Input() beneficiary!: Beneficiary | null;
  @Input() isControlsDisabled = true;
  @Output() btnClicked = new EventEmitter<BeneficiaryDetailsAction>();
}
