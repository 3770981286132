import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  FilterValues,
  ScheduledTransactionDetails,
  ScheduledTransactionListItem,
  TransactionListItem,
  MultiLegTransactionListItem,
  MultiLegTransactionDetails,
  FinancialAccountSelectionListItem,
  TransactionDetails,
  TransactionHistory,
} from '@shared/models';
import { AuthActions } from '@shared/store';
import {
  CardTransactionActions,
  MultiLegTransactionActions,
  ScheduledTransactionActions,
  SingleLegTransactionActions,
  TransactionsActions,
} from './transactions.actions';

export interface TransactionsState {
  cardTransactions: TransactionListItem[];
  cardTransactionsTotalElements: number;
  cardTransactionsActiveFilters: FilterValues | null;
  scheduledTransactions: ScheduledTransactionListItem[];
  scheduledTransactionsTotalElements: number;
  scheduledTransactionsActiveFilters: FilterValues | null;
  isLoading: boolean;
  scheduledTransactionDetails: ScheduledTransactionDetails | null;
  scheduledTransactionDetailsLoading: boolean;
  multiLegTransactions: MultiLegTransactionListItem[];
  multiLegTransactionsTotalElements: number;
  multiLegTransactionsActiveFilters: FilterValues | null;
  multiLegTransactionsLoading: boolean;
  multiLegTransactionDetails: MultiLegTransactionDetails | null;
  defaultBusinessAccountFinancialAccount: FinancialAccountSelectionListItem | null;
  singleLegTransactionDetails: TransactionDetails | null;
  singleLegTransactionHistory: TransactionHistory[] | null;
  multiLegTransactionHistory: TransactionHistory[] | null;
  scheduledTransactionHistory: TransactionHistory[] | null;
  /*card txns*/
  cardTransactionDetails: TransactionDetails | null;
}

export const initialState: TransactionsState = {
  cardTransactions: [],
  cardTransactionsTotalElements: 0,
  cardTransactionsActiveFilters: { date: 'DEFAULT' },
  scheduledTransactions: [],
  scheduledTransactionsTotalElements: 0,
  scheduledTransactionsActiveFilters: {},
  isLoading: false,
  scheduledTransactionDetails: null,
  scheduledTransactionDetailsLoading: false,
  multiLegTransactions: [],
  multiLegTransactionsTotalElements: 0,
  multiLegTransactionsActiveFilters: {},
  multiLegTransactionsLoading: false,
  multiLegTransactionDetails: null,
  defaultBusinessAccountFinancialAccount: null,
  singleLegTransactionDetails: null,
  singleLegTransactionHistory: null,
  multiLegTransactionHistory: null,
  scheduledTransactionHistory: null,
  /*card txns*/
  cardTransactionDetails: null,
};

const reducer = createReducer(
  initialState,
  on(TransactionsActions.loadCardTransactions, (state): TransactionsState => {
    return { ...state, cardTransactions: [], cardTransactionsTotalElements: 0, isLoading: true };
  }),
  on(TransactionsActions.loadCardTransactionsSuccess, (state, { transactionListItems, totalElements }): TransactionsState => {
    return { ...state, cardTransactions: transactionListItems, cardTransactionsTotalElements: totalElements, isLoading: false };
  }),
  on(TransactionsActions.loadCardTransactionsFailure, (state): TransactionsState => {
    return { ...state, cardTransactions: [], cardTransactionsTotalElements: 0, isLoading: false };
  }),
  on(TransactionsActions.setCardTransactionsActiveFilters, (state, { filters }): TransactionsState => {
    return { ...state, cardTransactionsActiveFilters: filters };
  }),
  on(ScheduledTransactionActions.load, (state): TransactionsState => {
    return { ...state, scheduledTransactions: [], scheduledTransactionsTotalElements: 0, isLoading: true };
  }),
  on(ScheduledTransactionActions.loadSuccess, (state, { transactionListItems, totalElements }): TransactionsState => {
    return { ...state, scheduledTransactions: transactionListItems, scheduledTransactionsTotalElements: totalElements, isLoading: false };
  }),
  on(ScheduledTransactionActions.loadFailure, (state): TransactionsState => {
    return { ...state, scheduledTransactions: [], scheduledTransactionsTotalElements: 0, isLoading: false };
  }),
  on(ScheduledTransactionActions.setFilters, (state, { filters }): TransactionsState => {
    return { ...state, scheduledTransactionsActiveFilters: filters };
  }),
  on(ScheduledTransactionActions.loadDetails, (state): TransactionsState => {
    return { ...state, scheduledTransactionDetails: null, scheduledTransactionDetailsLoading: true };
  }),
  on(ScheduledTransactionActions.loadDetailsSuccess, (state, { scheduledTransactionDetails }): TransactionsState => {
    return { ...state, scheduledTransactionDetails: scheduledTransactionDetails, scheduledTransactionDetailsLoading: false };
  }),
  on(ScheduledTransactionActions.loadDetailsFailure, (state): TransactionsState => {
    return { ...state, scheduledTransactionDetails: null, scheduledTransactionDetailsLoading: false };
  }),
  on(MultiLegTransactionActions.load, (state): TransactionsState => {
    return { ...state, multiLegTransactions: [], multiLegTransactionsTotalElements: 0, multiLegTransactionsLoading: true };
  }),
  on(MultiLegTransactionActions.loadSuccess, (state, { transactionListItems, totalElements }): TransactionsState => {
    return {
      ...state,
      multiLegTransactions: transactionListItems,
      multiLegTransactionsTotalElements: totalElements,
      multiLegTransactionsLoading: false,
    };
  }),
  on(MultiLegTransactionActions.loadFailure, (state): TransactionsState => {
    return { ...state, multiLegTransactions: [], multiLegTransactionsTotalElements: 0, multiLegTransactionsLoading: false };
  }),
  on(MultiLegTransactionActions.setFilters, (state, { filters }): TransactionsState => {
    return { ...state, multiLegTransactionsActiveFilters: filters };
  }),
  on(MultiLegTransactionActions.loadDetails, (state): TransactionsState => {
    return { ...state, multiLegTransactionDetails: null, multiLegTransactionHistory: null };
  }),
  on(MultiLegTransactionActions.loadDetailsSuccess, (state, { multiLegTransactionDetails }): TransactionsState => {
    return { ...state, multiLegTransactionDetails: multiLegTransactionDetails };
  }),
  on(MultiLegTransactionActions.loadDetailsFailure, (state): TransactionsState => {
    return { ...state, multiLegTransactionDetails: null };
  }),
  on(MultiLegTransactionActions.setDefaultBusinessAccountFinancialAccount, (state, { financialAccount }): TransactionsState => {
    return { ...state, defaultBusinessAccountFinancialAccount: financialAccount };
  }),
  on(MultiLegTransactionActions.loadTransactionHistorySuccess, (state, { transactionHistory }): TransactionsState => {
    return { ...state, multiLegTransactionHistory: transactionHistory };
  }),
  on(MultiLegTransactionActions.loadTransactionHistoryFailure, (state): TransactionsState => {
    return { ...state, multiLegTransactionHistory: [] };
  }),
  on(SingleLegTransactionActions.loadDetails, (state): TransactionsState => {
    return { ...state, singleLegTransactionDetails: null, singleLegTransactionHistory: null };
  }),
  on(SingleLegTransactionActions.loadDetailsSuccess, (state, { singleLegTransactionDetails }): TransactionsState => {
    return { ...state, singleLegTransactionDetails: singleLegTransactionDetails };
  }),
  on(SingleLegTransactionActions.loadDetailsFailure, (state): TransactionsState => {
    return { ...state, singleLegTransactionDetails: null };
  }),
  on(SingleLegTransactionActions.loadTransactionHistorySuccess, (state, { transactionHistory }): TransactionsState => {
    return { ...state, singleLegTransactionHistory: transactionHistory };
  }),
  on(SingleLegTransactionActions.loadTransactionHistoryFailure, (state): TransactionsState => {
    return { ...state, singleLegTransactionHistory: [] };
  }),
  on(ScheduledTransactionActions.loadTransactionHistorySuccess, (state, { transactionHistory }): TransactionsState => {
    return { ...state, scheduledTransactionHistory: transactionHistory };
  }),
  on(ScheduledTransactionActions.loadTransactionHistoryFailure, (state): TransactionsState => {
    return { ...state, scheduledTransactionHistory: [] };
  }),
  on(CardTransactionActions.loadDetails, (state): TransactionsState => {
    return { ...state, cardTransactionDetails: null };
  }),
  on(CardTransactionActions.loadDetailsSuccess, (state, { cardTransactionDetails }): TransactionsState => {
    return { ...state, cardTransactionDetails: cardTransactionDetails };
  }),
  on(CardTransactionActions.loadDetailsFailure, (state): TransactionsState => {
    return { ...state, cardTransactionDetails: null };
  }),
  on(AuthActions.logout, (): TransactionsState => initialState),
);

export const transactionFeature = createFeature({
  name: 'transactionFeature',
  reducer,
});

export const selectCardTransactionsActiveFiltersCount = createSelector(
  transactionFeature.selectCardTransactionsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return 0;
    }

    return Object.values(activeFilters).filter((value) => value !== 'DEFAULT').length;
  },
);

export const selectScheduledTransactionsActiveFiltersCount = createSelector(
  transactionFeature.selectScheduledTransactionsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return 0;
    }

    return Object.values(activeFilters).filter((value) => value !== 'DEFAULT').length;
  },
);

export const selectMultiLegTransactionsActiveFiltersCount = createSelector(
  transactionFeature.selectMultiLegTransactionsActiveFilters,
  (activeFilters) => {
    if (!activeFilters) {
      return 0;
    }

    return Object.values(activeFilters).filter((value) => value !== 'DEFAULT').length;
  },
);

export const selectSingleLegTransactionDetailsWithHistory = createSelector(
  transactionFeature.selectSingleLegTransactionDetails,
  transactionFeature.selectSingleLegTransactionHistory,
  (transactionDetails, transactionHistory) => {
    if (!transactionDetails || !transactionHistory) {
      return;
    }

    return {
      transactionDetails,
      transactionHistory,
    };
  },
);

export const selectMultiLegTransactionDetailsWithHistory = createSelector(
  transactionFeature.selectMultiLegTransactionDetails,
  transactionFeature.selectMultiLegTransactionHistory,
  (transactionDetails, transactionHistory) => {
    if (!transactionDetails || !transactionHistory) {
      return;
    }

    return {
      transactionDetails,
      transactionHistory,
    };
  },
);

export const selectScheduledTransactionDetailsWithHistory = createSelector(
  transactionFeature.selectScheduledTransactionDetails,
  transactionFeature.selectScheduledTransactionHistory,
  (transactionDetails, transactionHistory) => {
    if (!transactionDetails || !transactionHistory) {
      return;
    }

    return {
      transactionDetails,
      transactionHistory,
    };
  },
);
