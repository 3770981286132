import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { isValid } from 'date-fns';
import { isString } from 'lodash-es';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { filterDateToObject, filterFormatDate } from '@shared/utils';
import { FilterValue } from '@shared/models';

@Injectable()
class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly delimiter = '/';

  parse(value: string): NgbDateStruct | null {
    return filterDateToObject(value, this.delimiter);
  }

  format(date: NgbDateStruct | null): string {
    if (!date) {
      return '';
    }

    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return [month, day, date.year].join(this.delimiter);
  }
}

@Component({
  selector: 'app-filter-custom-date-selector',
  templateUrl: './filter-custom-date-selector.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
})
export class FilterCustomDateSelectorComponent implements AfterViewChecked, OnChanges {
  @ViewChild('datepicker') datepicker!: NgbDatepicker;

  @ViewChild('dpCustomDate') dpCustomDate!: ElementRef<HTMLInputElement>;

  @Input() props?: { [key: string]: string | undefined };

  @Input() selectedValue?: FilterValue;

  @Input() showCalendar?: boolean;

  @Output() changed = new EventEmitter<string>();

  @Output() errored = new EventEmitter<boolean>();

  @Output() closed = new EventEmitter();

  customDateModel: NgbDateStruct | null = null;

  customDate: NgbDate | null = null;

  hoveredDate: NgbDate | null = null;

  minStartDate = filterFormatDate(new Date('2021-01-01T00:00'));

  maxDate: NgbDateStruct | null = null;

  inputValue = this.formatter.format(this.customDate);

  constructor(public ref: ChangeDetectorRef, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValue?.currentValue && isString(changes.selectedValue.currentValue)) {
      const parsedDate = changes.selectedValue.currentValue.split('::')!;
      const [type, custom] = parsedDate;
      this.selectedValue = type;
      if (type === 'CUSTOM_DATE' && isValid(new Date(custom))) {
        this.customDate = filterFormatDate(new Date(custom + 'T00:00'));
        this.inputValue = this.formatter.format(this.customDate);
      } else {
        this.customDate = null;
        this.datepicker?.writeValue(undefined);
        this.customDateModel = null;
        this.inputValue = this.formatter.format(this.customDate);
      }

      this.validateCustomDate();
    }
  }

  customDateInput(currentValue: NgbDate | null, input: string): void {
    const parsed = this.formatter.parse(input);
    const result = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;

    if (!result || (result && `${result.year}`.length !== 4) || result.year < 2020) {
      return;
    }

    this.datepicker?.writeValue(result);
    this.customDate = result;
    this.datepicker?.navigateTo(result);
  }

  customDateBlur(currentValue: NgbDate | null) {
    if (this.dpCustomDate?.nativeElement) {
      this.dpCustomDate.nativeElement.value = this.formatter.format(currentValue);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.customDate) {
      this.customDate = date;
      this.inputValue = this.formatter.format(this.customDate);
    } else {
      this.customDate = date;
    }

    if (this.props?.maxMonths) {
      this.maxDate = this.calendar.getNext(this.customDate, 'm', parseInt(this.props.maxMonths, 10));
    }
  }

  validateCustomDate() {
    if (this.selectedValue === 'CUSTOM_DATE' && !this.customDate) {
      this.errored.emit(true);
      return;
    }

    this.errored.emit(false);
  }

  onSave(): void {
    this.validateCustomDate();
    if (!this.customDate) {
      return;
    }

    const { year, month, day } = this.customDate;
    const customDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    this.changed.emit(`CUSTOM_DATE::${customDate}`);
    this.closed.emit();
  }

  onClose(): void {
    this.closed.emit();
  }
}
