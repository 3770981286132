import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AdaRegistrationService } from '@shared/services';
import { AdaRegistrationActions } from '@shared/store';
import { selectRouteParams, selectUrl } from '../core';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { ErrorUtils } from '@shared/utils';
import { MessagesActions } from '../core/messages';

@Injectable()
export class AdaRegistrationDetailsEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private adaRegistrationService: AdaRegistrationService,
    private store: Store,
  ) {}

  getAdaRegistrationDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationActions.initialize, AdaRegistrationActions.loadAdaRegistrationDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.adaRegistrationService.getAdaRegistrationById(routeParams.id).pipe(
          map((response) =>
            AdaRegistrationActions.loadAdaRegistrationDetailsSuccess({
              adaRegistration: JSON.parse(JSON.stringify(response)),
            }),
          ),
          catchError((error) => {
            return of(AdaRegistrationActions.loadAdaRegistrationDetailsError({ error }));
          }),
        );
      }),
    );
  });

  loadAdaRegistrationDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationActions.loadAdaRegistrationDetailsError),
      tap({
        next: (loadAdaRegistrationDetailsError) => {
          ErrorUtils.catchError('adaRegistrationService.getAdaRegistrationById error', loadAdaRegistrationDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Debit Authorization.', title: 'Error' }))),
    );
  });

  loadAdaRegistrationDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AdaRegistrationActions.loadAdaRegistrationDetailsError),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -1).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  updateAdaRegistrationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationActions.updateAdaRegistrationStatus),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([payload, routeParams]) => {
        return this.adaRegistrationService.updateAdaRegistrationStatus(payload.action, routeParams.id).pipe(
          map((response) =>
            AdaRegistrationActions.loadAdaRegistrationDetailsSuccess({
              adaRegistration: JSON.parse(JSON.stringify(response)),
            }),
          ),
          catchError((error) => of(AdaRegistrationActions.updateAdaRegistrationStatusError({ error, action: payload.action }))),
        );
      }),
    );
  });

  updateAdaRegistrationStatusError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationActions.updateAdaRegistrationStatusError),
      tap({
        next: (statusError) => {
          ErrorUtils.catchError('adaRegistrationService.updateAdaRegistrationStatus error', statusError.error);
        },
      }),
      switchMap((statusError) =>
        of(MessagesActions.displayError({ message: `Unable to ${statusError.action} Debit Authorization.`, title: 'Error' })),
      ),
    );
  });

  updateAdaRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationActions.updateAdaRegistration),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([editParams, routeParams]) => {
        return this.adaRegistrationService.updateAdaRegistration(editParams.payload, routeParams.id).pipe(
          map((response) =>
            AdaRegistrationActions.loadAdaRegistrationDetailsSuccess({
              adaRegistration: JSON.parse(JSON.stringify(response)),
            }),
          ),
          catchError((error) => of(AdaRegistrationActions.updateAdaRegistrationError(error))),
        );
      }),
    );
  });

  updateAdaRegistrationError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdaRegistrationActions.updateAdaRegistrationError),
      tap({
        next: (statusError) => {
          ErrorUtils.catchError('adaRegistrationService.updateAdaRegistration error', statusError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: `Unable to Update Debit Authorization.`, title: 'Error' }))),
    );
  });
}
