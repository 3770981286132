<div
  #container
  class="rkt-filter-menu"
  *ngIf="isVisible"
  [@overlayAnimation]="{
    value: 'visible',
    params: { showTransitionParams: '50ms linear', hideTransitionParams: '50ms linear' }
  }"
  (@overlayAnimation.start)="captureStartEvent($event)"
>
  <div class="rkt-filter-menu-content">
    <div class="filters-scroll-area">
      <div class="filter-scroll-item" *ngFor="let filter of filters">
        <app-filter-radio-group
          *ngIf="filter.type === 'RADIO'"
          [options]="filter.options!"
          [props]="filter.props"
          (changed)="setFilterValue(filter.name, $event)"
          (filterToggled)="onFilterToggle()"
          [selectedValue]="values[filter.name]"
          [defaultValue]="filter.defaultValue"
          [filterName]="filter.displayName"
          [isCollapsable]="isFilterCollapsable"
        ></app-filter-radio-group>

        <app-filter-checklist
          *ngIf="filter.type === 'CHECKLIST'"
          [options]="filter.options!"
          [props]="filter.props"
          (changed)="setFilterValue(filter.name, $event)"
          (filterToggled)="onFilterToggle()"
          [selectedValue]="values[filter.name]"
          [filterName]="filter.displayName"
          [defaultValue]="filter.defaultValue"
        ></app-filter-checklist>

        <app-filter-input
          *ngIf="filter.type === 'INPUT'"
          [props]="filter.props"
          (changed)="setFilterValue(filter.name, $event)"
          (filterToggled)="onFilterToggle()"
          [selectedValue]="values[filter.name]"
          [filterName]="filter.displayName"
          (errored)="setFieldErrorState(filter.name, $event)"
          [isCollapsable]="isFilterCollapsable"
        ></app-filter-input>

        <app-filter-amount
          *ngIf="filter.type === 'AMOUNT'"
          [props]="filter.props"
          (changed)="setFilterValue(filter.name, $event)"
          (filterToggled)="onFilterToggle()"
          [selectedValue]="values[filter.name]"
          [filterName]="filter.displayName"
          (errored)="setFieldErrorState(filter.name, $event)"
          [isCollapsable]="isFilterCollapsable"
        ></app-filter-amount>

        <app-filter-date
          *ngIf="filter.type === 'DATE' || filter.type === 'CUSTOM_DATE'"
          [options]="filter.options!"
          [props]="filter.props"
          (changed)="setFilterValue(filter.name, $event)"
          (edit)="onEditClick(filter)"
          (filterToggled)="onFilterToggle()"
          [selectedValue]="values[filter.name]"
          [defaultValue]="filter.defaultValue"
          [filterName]="filter.displayName"
          [fieldError]="errors[filter.name]"
          [isCalendarVisible]="getCalendarVisibility(filter.name)"
          [isCollapsable]="isFilterCollapsable"
        ></app-filter-date>
      </div>
    </div>
  </div>

  <app-filter-custom-date-selector
    *ngFor="let filter of customerDateFilters"
    [selectedValue]="values[filter.name]"
    [props]="filter.props"
    [showCalendar]="getCalendarVisibility(filter.name)"
    (changed)="setFilterValue(filter.name, $event)"
    (errored)="setFieldErrorState(filter.name, $event)"
    (closed)="closeCalendar(filter.name)"
  ></app-filter-custom-date-selector>

  <app-filter-custom-date-range-selector
    *ngFor="let filter of dateRangeFilters"
    [calendarDisplayName]="filter.displayName"
    [selectedValue]="values[filter.name]"
    [props]="filter.props"
    [showCalendar]="getCalendarVisibility(filter.name)"
    (changed)="setFilterValue(filter.name, $event)"
    (errored)="setFieldErrorState(filter.name, $event)"
    (closed)="closeCalendar(filter.name)"
  ></app-filter-custom-date-range-selector>

  <div class="rkt-filter-menu-buttons">
    <button rktButton btnType="danger" (click)="onResetAllFilterBtnClick()">Reset All</button>
    <button rktButton (click)="onApplyFilterBtnClick()" [disabled]="isFilterHasErrors">Apply</button>
  </div>
</div>
