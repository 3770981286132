<div class="filter-date-selector" [hidden]="selectedValue !== 'CUSTOM' || !showCalendar">
  <div class="title">Custom range - {{ calendarDisplayName }}</div>
  <div class="selected-range">
    <div class="from-to">
      <label class="date-label" for="from-date">Start Date</label>

      <div class="date-field">
        <input
          #dpCustomDateFrom
          id="from-date"
          class="date-input"
          placeholder="mm/dd/yyyy"
          mask="M0/d0/0000"
          (focus)="datepicker.navigateTo(fromDate || undefined)"
          (input)="customFromDateInput(fromDate, dpCustomDateFrom.value)"
          (blur)="customFromDateBlur(fromDate)"
          [(ngModel)]="inputFromValue"
          [value]="inputFromValue || ''"
        />
        <rkt-icon class="calendar-icon" name="calendar"></rkt-icon>
      </div>
    </div>

    <div class="from-to">
      <label class="date-label" for="to-date">End Date</label>

      <div class="date-field">
        <input
          #dpCustomDateTo
          id="to-date"
          [disabled]="!fromDate"
          class="date-input"
          placeholder="mm/dd/yyyy"
          [class.active]="!toDate"
          mask="M0/d0/0000"
          (focus)="datepicker.navigateTo(toDate || undefined)"
          (input)="customToDateInput(toDate, dpCustomDateTo.value)"
          (blur)="customToDateBlur(toDate)"
          [(ngModel)]="inputToValue"
          [value]="formatter.format(toDate)"
        />
        <button class="clear-btn" (click)="onClearEndDateClick()" *ngIf="toDate"><rkt-icon name="times"></rkt-icon></button>
      </div>
    </div>
  </div>

  <ngb-datepicker
    #datepicker
    *ngIf="selectedValue === 'CUSTOM'"
    [(ngModel)]="customDateModel"
    [minDate]="minDate!"
    [maxDate]="maxDate!"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [dayTemplate]="t"
  >
  </ngb-datepicker>

  <ng-template
    #t
    let-date
    let-focused="focused"
    let-today="today"
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
  >
    <span
      class="custom-day"
      [class.selected]="selected"
      [class.disabled]="disabled"
      [class.focused]="focused"
      [class.today]="today"
      [class.start]="isStartDate(date)"
      [class.end]="isEndDate(date)"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      [title]="date.day"
      [class.text-muted]="date.month !== currentMonth"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>

  <div class="calendar-buttons">
    <button type="button" class="table-header-filter-btn" (click)="onClose()" #filterBtn>Cancel</button>

    <button rktButton (click)="onSave()" title="Save" [disabled]="!fromDate || !toDate" class="add-entity-btn">Save</button>
  </div>
</div>
