import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { flatten } from 'lodash-es';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, tap, switchMap, defaultIfEmpty } from 'rxjs/operators';
import { CardAccountService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import {
  CardAccountActions,
  CardProductDetailsActions,
  CardProductsActions,
  MccActions,
  MessagesActions,
  fromAuth,
  selectRouteParams,
  selectUrl,
} from '@shared/store';

@Injectable()
export class CardAccountEffects {
  constructor(private actions$: Actions, private cardAccountService: CardAccountService, private router: Router, private store: Store) {}

  getCardAccountById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardAccountDetails),
      concatLatestFrom(() => [this.store.select(selectRouteParams), this.store.select(fromAuth.selectBusinessAccountId)]),
      mergeMap(([, routeParams, activeBusinessAccountId]) => {
        return this.cardAccountService
          .getCardAccountDetails({ businessAccountId: activeBusinessAccountId, cardAccountId: routeParams.cardId })
          .pipe(
            map((response) => CardAccountActions.loadCardAccountDetailsSuccess({ financialAccount: response })),
            catchError((error) => {
              return of(CardAccountActions.loadCardAccountDetailsError({ error }));
            }),
          );
      }),
    );
  });

  loadCardAccountDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardAccountDetailsError),
      tap({
        next: (loadFinancialAccountError) => {
          ErrorUtils.catchError('cardAccountService.getCardAccountDetails error', loadFinancialAccountError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Account Details.' }))),
    );
  });

  loadCardAccountDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CardAccountActions.loadCardAccountDetailsError),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -2).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  loadRestrictions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictions),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardRestrictions({ financialAccountId: routeParams.cardId }).pipe(
          map((response) => CardAccountActions.loadRestrictionsSuccess({ restrictionList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadRestrictionsError({ error }));
          }),
        );
      }),
    );
  });

  loadRestrictionsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictionsError),
      tap({
        next: (loadRestrictionsError) => {
          ErrorUtils.catchError('cardAccountService.getRestrictions error', loadRestrictionsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Restrictions.', title: 'Error' }))),
    );
  });

  loadRestrictionDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictionDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService
          .getCardRestrictionById({ financialAccountId: routeParams.cardId, restrictionId: routeParams.id })
          .pipe(
            map((response) => CardAccountActions.loadRestrictionDetailsSuccess({ restrictionDetails: response })),
            catchError((error) => {
              return of(CardAccountActions.loadRestrictionDetailsError({ error }));
            }),
          );
      }),
    );
  });

  loadRestrictionDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadRestrictionDetailsError),
      tap({
        next: (loadRestrictionDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardRestrictionById error', loadRestrictionDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Restriction Details.', title: 'Error' }))),
    );
  });

  loadAlerts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlerts),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardAlerts({ financialAccountId: routeParams.cardId }).pipe(
          map((response) => CardAccountActions.loadAlertsSuccess({ alertList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadAlertsError({ error }));
          }),
        );
      }),
    );
  });

  loadAlertsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlertsError),
      tap({
        next: (loadAlertsError) => {
          ErrorUtils.catchError('cardAccountService.getCardAlerts error', loadAlertsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Alerts.', title: 'Error' }))),
    );
  });

  loadAlertDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlertDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardAlertById({ financialAccountId: routeParams.cardId, alertId: routeParams.id }).pipe(
          map((response) => CardAccountActions.loadAlertDetailsSuccess({ alertDetails: response })),
          catchError((error) => {
            return of(CardAccountActions.loadAlertDetailsError({ error }));
          }),
        );
      }),
    );
  });

  loadAlertDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadAlertDetailsError),
      tap({
        next: (loadAlertDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardAlertById error', loadAlertDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Alert Details.', title: 'Error' }))),
    );
  });

  loadDisputes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputes),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardDisputes({ financialAccountId: routeParams.cardId }).pipe(
          map((response) => CardAccountActions.loadDisputesSuccess({ disputeList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadDisputesError({ error }));
          }),
        );
      }),
    );
  });

  loadDisputesError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputesError),
      tap({
        next: (loadDisputesError) => {
          ErrorUtils.catchError('cardAccountService.getCardDisputes error', loadDisputesError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Disputes.', title: 'Error' }))),
    );
  });

  loadDisputeDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputeDetails),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardDisputeById({ financialAccountId: routeParams.cardId, disputeId: routeParams.id }).pipe(
          map((response) => CardAccountActions.loadDisputeDetailsSuccess({ disputeDetails: response })),
          catchError((error) => {
            return of(CardAccountActions.loadDisputeDetailsError({ error }));
          }),
        );
      }),
    );
  });

  loadDisputeDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadDisputeDetailsError),
      tap({
        next: (loadDisputeDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardDisputeById error', loadDisputeDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Dispute Details.', title: 'Error' }))),
    );
  });

  loadStatements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadStatements),
      concatLatestFrom(() => this.store.select(selectRouteParams)),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardStatements({ financialAccountId: routeParams.cardId }).pipe(
          map((response) => CardAccountActions.loadStatementsSuccess({ statementList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadStatementsError({ error }));
          }),
        );
      }),
    );
  });

  loadStatementsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadStatementsError),
      tap({
        next: (loadStatementsError) => {
          ErrorUtils.catchError('cardAccountService.getCardStatements error', loadStatementsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Statements.', title: 'Error' }))),
    );
  });

  loadCardAccountDetailsPageErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          CardAccountActions.loadRestrictionDetailsError,
          CardAccountActions.loadDisputeDetailsError,
          CardAccountActions.loadAlertDetailsError,
          CardAccountActions.loadCardProgramsError,
        ),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -1).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );

  loadCardPrograms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardPrograms),
      switchMap(() => {
        return this.cardAccountService.getCardPrograms().pipe(
          map((response) => CardAccountActions.loadCardProgramsSuccess({ cardProgramList: response })),
          catchError((error) => {
            return of(CardAccountActions.loadCardProgramsError({ error }));
          }),
        );
      }),
    );
  });

  loadCardProgramsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.loadCardProgramsError),
      tap({
        next: (loadCardProgramsError) => {
          ErrorUtils.catchError('cardAccountService.getCardPrograms error', loadCardProgramsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Programs.' }))),
    );
  });

  mccItemsLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MccActions.load),
      switchMap(() => {
        return this.cardAccountService.getMccItems().pipe(
          map((mccItems) => {
            return MccActions.loadSuccess({ mccItems });
          }),
          catchError((error) => {
            return of(MccActions.loadFailure({ error }));
          }),
        );
      }),
    );
  });

  mccItemsLoadFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MccActions.loadFailure),
      tap({
        next: (errorResponse) => {
          ErrorUtils.catchError('cardAccountService.getMccItems error', errorResponse.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Merchant Category Codes.' }))),
    );
  });

  loadCardIssueSupportedLanguages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.cardIssueFormInit, CardAccountActions.loadCardAccountDetails),
      switchMap(() => {
        return this.cardAccountService.getCardIssueSupportedLanguages().pipe(
          map((response) => CardAccountActions.loadCardIssueSupportedLanguagesSuccess({ cardIssueSupportedLanguages: response })),
          catchError(() => of()),
        );
      }),
    );
  });

  loadCardProductsForFinancialAccountForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardAccountActions.cardIssueFormInit),
      switchMap(() => this.cardAccountService.getCardPrograms()),
      switchMap((cardPrograms) => {
        const qrailsPrograms = cardPrograms.filter((program) => program.transactionProcessor === 'QRails');

        return forkJoin(
          qrailsPrograms.map((program) =>
            this.cardAccountService.getCardProducts(program.id).pipe(
              map((programProducts) => {
                return programProducts.map((product) => ({ ...product, solutionName: program.solutionName }));
              }),
            ),
          ),
        ).pipe(
          defaultIfEmpty([]),
          map((response) => CardProductsActions.loadSuccess({ cardProducts: flatten(response) })),
          catchError(() => of()),
        );
      }),
    );
  });

  loadCardProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductsActions.load),
      switchMap(() => this.cardAccountService.getCardPrograms()),
      switchMap((cardPrograms) => {
        const qrailsPrograms = cardPrograms.filter((program) => program.transactionProcessor === 'QRails');

        return forkJoin(
          qrailsPrograms.map((program) =>
            this.cardAccountService.getCardProducts(program.id).pipe(
              map((programProducts) => {
                return programProducts.map((product) => ({ ...product, solutionName: program.solutionName }));
              }),
            ),
          ),
        ).pipe(
          defaultIfEmpty([]),
          map((response) => CardProductsActions.loadSuccess({ cardProducts: flatten(response) })),
          catchError((error) => {
            return of(CardProductsActions.loadFailure({ error }));
          }),
        );
      }),
    );
  });

  loadCardProductsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductsActions.loadFailure),
      tap({
        next: (loadCardProductsError) => {
          ErrorUtils.catchError('cardAccountService.getCardProducts error', loadCardProductsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Products.' }))),
    );
  });

  loadCardProductDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductDetailsActions.load),
      concatLatestFrom(() => [this.store.select(selectRouteParams)]),
      mergeMap(([, routeParams]) => {
        return this.cardAccountService.getCardProductDetails({ programId: routeParams.programId, productId: routeParams.productId }).pipe(
          map((response) => CardProductDetailsActions.loadSuccess({ cardProduct: response })),
          catchError((error) => {
            return of(CardProductDetailsActions.loadFailure({ error }));
          }),
        );
      }),
    );
  });

  loadCardProductDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CardProductDetailsActions.loadFailure),
      tap({
        next: (loadCardProductDetailsError) => {
          ErrorUtils.catchError('cardAccountService.getCardProductDetails error', loadCardProductDetailsError.error);
        },
      }),
      switchMap(() => of(MessagesActions.displayError({ message: 'Unable to fetch Card Product Details.' }))),
    );
  });

  loadCardProductDetailsErrorRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CardProductDetailsActions.loadFailure),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap({
          next: ([, currentUrl]) => {
            const prevUrl = currentUrl.split('/').slice(0, -2).join('/');
            this.router.navigateByUrl(prevUrl);
          },
        }),
      );
    },
    { dispatch: false },
  );
}
