import { Component, EventEmitter, Input, Output } from '@angular/core';

export type DocumentDetailsAction = 'DELETE';

@Component({
  selector: 'app-document-details-header',
  templateUrl: './document-details-header.component.html',
})
export class DocumentDetailsHeaderComponent {
  @Input() isControlsDisabled = true;
  @Output() btnClicked = new EventEmitter<DocumentDetailsAction>();
}
