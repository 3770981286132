import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { Subject, catchError, exhaustMap, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { MultiLegTransactionDetails, MultiLegTransactionLegDetails, TemplateContext, TransactionHistory } from '@shared/models';
import {
  MultiLegTransactionActions,
  TransactionFormActions,
  transactionFeature,
  transactionFormFeature,
  selectMultiLegTransactionDetailsWithHistory,
} from '@shared/store';
import {
  AttachmentsListComponent,
  CancelRecoveryFinancialAccountModalComponent,
  CancelRecoveryFinancialAccountReviewModalComponent,
  ConfirmModalComponent,
  CreateTransactionReviewModalComponent,
  ErrorModalComponent,
  MultiLegTransactionEditModalComponent,
  SuccessModalComponent,
} from '@shared/components';
import { ErrorUtils } from '@shared/utils';
import { MultiLegTransactionsService, NoteService } from '@shared/services';
import { ERROR_MAP } from '@shared/constants';

interface MoveFromToTemplateContext extends TemplateContext<MultiLegTransactionLegDetails> {
  transaction?: MultiLegTransactionDetails;
}

@Component({
  templateUrl: './multi-leg-transaction-details.component.html',
  styleUrls: ['./multi-leg-transaction-details.component.scss'],
})
export class MultiLegTransactionDetailsComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('attachmentsList') attachmentsList!: AttachmentsListComponent;

  pageReturnURL = '/app/transactions/multi-leg-transactions';

  breadcrumbs = [{ label: 'MLT Activity', url: this.pageReturnURL }, { label: 'MLT Details' }];

  multiLegTransaction$ = this.store.select(selectMultiLegTransactionDetailsWithHistory);

  toAccountLink = '';

  fromAccountLink = '';

  isShowMetadata = false;

  multiLegTransactionDetailsContext?: TemplateContext<MultiLegTransactionDetails>;

  legDetailsContext?: MoveFromToTemplateContext;

  transactionHistoryItems: TransactionHistory[] = [];

  get multiLegTransactionId(): string {
    return this.route.snapshot.paramMap.get('multiLegTransactionId') ?? '';
  }

  private readonly multiLegTransactionsService = inject(MultiLegTransactionsService);

  private readonly noteService = inject(NoteService);

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private ref: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(MultiLegTransactionActions.loadDetails());
        },
      });
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.store.dispatch(TransactionFormActions.createTransactionPageReset());

    this.destroy$.next();
    this.destroy$.complete();
  }

  onEditBtnClick(): void {
    this.store.dispatch(TransactionFormActions.formUpdateMltInitialize());
    this.onEditStage();
  }

  onEditStage(): void {
    const editModalRef = this.modalService.open(MultiLegTransactionEditModalComponent, {
      className: 'multi-leg-transaction-edit-modal',
    });

    editModalRef.result.then(
      (action?: 'REVIEW') => {
        if (action === 'REVIEW') {
          this.onReviewStage();
        }
      },
      () => false,
    );
  }

  onReviewStage(): void {
    const reviewModalRef = this.modalService.open(CreateTransactionReviewModalComponent, { className: 'create-transaction-review-modal' });

    reviewModalRef.componentInstance.modalInitData({ isUpdate: true });

    reviewModalRef.result.then(
      ({ action, isTransactionCreated, error } = {}) => {
        if (action === 'EDIT') {
          this.onEditStage();
        } else if (error) {
          this.onSumbitError(error);
        } else if (isTransactionCreated) {
          this.onSuccessModalClose();
        }
      },
      () => false,
    );
  }

  onSumbitError(error: string | number | HttpErrorResponse) {
    if (error === HttpStatusCode.InternalServerError) {
      return;
    }

    const errorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal create-transaction-error-modal' });

    const messageText = ErrorUtils.parseExtendedError(error);

    errorModalRef.componentInstance.messageText = ERROR_MAP.has(messageText) ? ERROR_MAP.get(messageText) : messageText;
  }

  onSuccessModalClose() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(
      () => {
        this.router.navigate(['app', 'transactions', 'multi-leg-transactions', this.multiLegTransactionId]);
      },
      () => null,
    );
  }

  onCancelBtnClick({ stage, status }: { stage: MultiLegTransactionDetails['stage']; status: MultiLegTransactionDetails['status'] }) {
    this.store.dispatch(TransactionFormActions.formCancelMltInitialize());

    if (status === 'PENDING') {
      this.onCancel();
    } else if ((stage === 'FUNDING' && status !== 'FAILED') || (stage === 'DISBURSING' && ['FAILED', 'PENDING'].includes(status))) {
      this.showCancelRecoveryFinancialAccountModal();
    }
  }

  onCancel(financialAccountId?: string) {
    const cancelConfirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    cancelConfirmModalRef.componentInstance.customText =
      'Canceling a transaction cannot be undone. Are you sure you want to cancel the transaction?';
    cancelConfirmModalRef.componentInstance.title = 'Cancel Transaction';
    cancelConfirmModalRef.componentInstance.confirmType = 'error';

    cancelConfirmModalRef.result.then(
      (result) => {
        if (result) {
          this.cancelMltTransaction(financialAccountId);
        }
      },
      () => false,
    );
  }

  cancelMltTransaction(financialAccountId?: string) {
    this.store
      .select(transactionFeature.selectMultiLegTransactionDetails)
      .pipe(
        exhaustMap((transaction) => this.multiLegTransactionsService.cancelMltTransaction(transaction!.id, financialAccountId)),
        concatLatestFrom(() => [this.store.select(transactionFormFeature.selectNote)]),
        switchMap(([transactionResponse, note]) => {
          if (!note) {
            return of(transactionResponse);
          }

          return this.noteService
            .addNote({
              entityId: transactionResponse.id,
              entityType: 'TRANSACTION',
              contentText: note,
            })
            .pipe(
              catchError((error) => {
                ErrorUtils.catchError('noteService.addNote', error);
                return of(transactionResponse);
              }),
            );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          const successModalRef = this.modalService.open(SuccessModalComponent, { className: 'confirm-modal' });
          successModalRef.componentInstance.customTitle = 'Transaction successfully cancelled.';
          successModalRef.result.finally(() => this.onSuccessModalClose());
        },
        error: (errorRes) => {
          this.onSumbitError(errorRes);
        },
      });
  }

  showCancelRecoveryFinancialAccountModal() {
    const cancelRecoveryFinancialAccountModalRef = this.modalService.open(CancelRecoveryFinancialAccountModalComponent, {
      className: 'cancel-recovery-financial-account-modal',
    });

    cancelRecoveryFinancialAccountModalRef.result.then(
      ({ action } = {}) => {
        if (action === 'CANCEL_REVIEW') {
          this.showCancelRecoveryFinancialAccountReviewModal();
        }
      },
      () => false,
    );
  }

  showCancelRecoveryFinancialAccountReviewModal() {
    const cancelRecoveryFinancialAccountReviewModalRef = this.modalService.open(CancelRecoveryFinancialAccountReviewModalComponent, {
      className: 'cancel-recovery-financial-account-review-modal',
    });

    cancelRecoveryFinancialAccountReviewModalRef.result.then(
      ({ action, financialAccountId }) => {
        if (action === 'CANCEL_EDIT') {
          this.showCancelRecoveryFinancialAccountModal();
        } else if (action === 'CANCEL_CONFIRM') {
          this.onCancel(financialAccountId);
        }
      },
      () => false,
    );
  }
}
