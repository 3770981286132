import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { formCheckboxGroup, formRadioGroup, formSelectMulti } from '@rocketfinancialcorp/rocket-ui/form';
import { Beneficiaries, FormModel, LinxEnhanceProfileDetails, LinxRequestBenificiaryDetails } from '@shared/models';
import { linxBeneficiaryProfileFields } from './linx-request-enhance-beneficiary-utils';
import { CustomerService } from '@shared/services';
import { catchError, map, Observable, of } from 'rxjs';
import { ErrorUtils } from '@shared/utils';
import { LinxBeneficiaryProfileType, LinxCustomerProfileType } from '@shared/constants';

interface EnhanceBeneficiaryFormModel {
  beneficiaryType: LinxBeneficiaryProfileType;
  beneficiaryIds: string[];
  personalInfo: Record<string, boolean>;
  documentTypes: Record<string, boolean>;
  addresses: Record<string, boolean>;
}

@Component({
  selector: 'app-linx-request-enhance-beneficiary',
  templateUrl: './linx-request-enhance-beneficiary.component.html',
})
export class LinxRequestEnhanceBeneficiaryComponent implements OnInit {
  private customerService = inject(CustomerService);

  @Input() customerId?: string;

  @Input() isTemplate = false;

  @Input() customerProfileType? = LinxCustomerProfileType.New;

  @Input() selectedEnhanceBeneficiaryDetails?: LinxRequestBenificiaryDetails;

  @Output() continue = new EventEmitter<LinxRequestBenificiaryDetails>();

  beneficiaryProfileTypeForm = new FormGroup({});

  formModel: FormModel<EnhanceBeneficiaryFormModel> = {
    beneficiaryType: LinxBeneficiaryProfileType.New,
  };

  beneficiaryProfileTypeFields = [
    formRadioGroup({
      key: 'beneficiaryType',
      label: '',
      className: 'linx-template-customer-radio-buttons',
      props: {
        required: true,
        options: [
          { label: 'Onboard Beneficial Owners', value: 'NEW' },
          { label: 'Enhance Existing Beneficial Owners', value: 'EXISTING' },
        ],
        change: () => this.updateEnhanceProfileValidation(),
      },
      expressions: {
        hide: () => this.customerProfileType === LinxCustomerProfileType.New,
      },
    }),
    formSelectMulti({
      key: 'beneficiaryIds',
      label: 'Existing Beneficiary',
      className: 'linx-financial-account-dropdown',
      props: {
        options: [],
        placeholder: 'Select Beneficiary',
        required: true,
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.model.beneficiaryType !== LinxBeneficiaryProfileType.Existing || this.isTemplate,
        'props.options': () => this.beneficiaries$,
      },
    }),
  ];

  documentFields = [
    formCheckboxGroup({
      key: 'documentTypes',
      label: '',
      className: 'linx-checkbox-enhance-fields',
      props: {
        options: [
          { value: 'driving-license', label: 'Drivers License' },
          { value: 'passport', label: 'Passport' },
          { value: 'other', label: 'Other' },
        ],
      },
    }),
  ];

  profileFormModel: FormModel<Record<string, Record<string, boolean> | string>> = {};

  profileFields: FormlyFieldConfig[] = linxBeneficiaryProfileFields;

  beneficiaries$: Observable<Beneficiaries[] | null> = of([]);

  ngOnInit(): void {
    this.getBusinessCustomerBeneficeries();
    this.setEnhanceProfile();
  }

  updateEnhanceProfileValidation(): void {
    this.profileFields = [...linxBeneficiaryProfileFields];
  }

  getBusinessCustomerBeneficeries(): void {
    if (!this.customerId) return;

    this.beneficiaries$ = this.customerService.getBusinessCustomerBeneficiaries(this.customerId).pipe(
      map((beneficiaries) => {
        return beneficiaries.map((beneficiary) => {
          return {
            label: beneficiary.fullName,
            value: beneficiary.id,
            ...beneficiary,
          };
        });
      }),
      catchError((error?: string) => {
        ErrorUtils.catchError('customerService.getBusinessCustomerBeneficiaries error', error);
        return of([]);
      }),
    );
  }

  onClickContinue(): void {
    const {
      beneficiaryType = LinxBeneficiaryProfileType.New,
      beneficiaryIds,
      personalInfo,
      documentTypes = {},
      addresses,
    } = this.formModel;

    const profileDetails: LinxEnhanceProfileDetails = {};

    const personalInfoDetails = personalInfo ? Object.keys(personalInfo).filter((key) => personalInfo[key]) : [];
    if (personalInfoDetails.length) {
      const beneficiaryInfo: string[] = [];
      personalInfoDetails.forEach((item: string) => {
        if (item === 'email') {
          profileDetails.email = ['PRIMARY'];
        } if (item === 'phoneNumber') {
          profileDetails.phoneNumber = ['HOME'];
        } else {
          beneficiaryInfo.push(item);
        }
      })
      if (beneficiaryInfo.length) {
        profileDetails.personalInfo = beneficiaryInfo;
      }
    }

    const addressesDetails = addresses ? Object.keys(addresses).filter((key) => addresses[key]) : [];
    if (addressesDetails.length) {
      profileDetails.addresses = addressesDetails;
    }

    const beneficiaryDetails: LinxRequestBenificiaryDetails = {
      beneficiaryType,
      beneficiaryIds,
      enhanceProfileDetails: Object.keys(profileDetails).length ? profileDetails : undefined,
      documentTypes: Object.keys(documentTypes).filter((key) => documentTypes[key]),
    };

    this.continue.emit(beneficiaryDetails);
  }

  onClearBtnClick(): void {
    this.beneficiaryProfileTypeForm.reset();
  }

  private setEnhanceProfile(): void {
    if (!this.selectedEnhanceBeneficiaryDetails) return;
    this.formModel = {
      beneficiaryType: this.selectedEnhanceBeneficiaryDetails.beneficiaryType,
    };

    const { personalInfo, addresses } = this.selectedEnhanceBeneficiaryDetails.enhanceProfileDetails ?? {};

    if (personalInfo?.length) {
      const personalFormInfo = {} as { [key: string]: boolean };
      personalInfo.forEach((info: string) => {
        personalFormInfo[info] = true;
      });
      this.formModel.personalInfo = personalFormInfo;
    }

    if (addresses?.length) {
      const addressesInfo = {} as { [key: string]: boolean };
      addresses.forEach((address: string) => {
        addressesInfo[address] = true;
      });
      this.formModel.addresses = addressesInfo;
    }
  }
}
