import { Component, Input, OnInit } from '@angular/core';
import { AdaRegistrationListActions, fromAdaRegistration } from '@shared/store';
import { AdaRegistration, FilterField, FilterValues, RequestPageParams } from '@shared/models';
import { PaymentFrequency, TransactionOccurrenceType } from '@shared/constants';
import { DatatableColumn, DatatableSorting, FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { AdaRegistrationService } from '@shared/services';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, isUndefined } from 'lodash/index';
import { activeFilters } from '@shared/utils';

@Component({
  selector: 'app-debit-authorization-list',
  templateUrl: './debit-authorization-list.component.html',
})
export class DebitAuthorizationListComponent implements OnInit {
  @Input() accountHolderIds?: string[];

  @Input() showPageTitle = true;

  registrations$ = this.store.select(fromAdaRegistration.selectAdaRegistrationList);

  totalElements$ = this.store.select(fromAdaRegistration.selectAdaRegistrationsTotalElements);

  loading$ = this.store.select(fromAdaRegistration.selectAdaRegistrationsLoading);

  searchString?: string;

  activeFilters: FilterValues = {};

  filters: FilterField[] = [
    {
      name: 'id',
      displayName: 'Debit Authorization ID',
      type: 'INPUT',
    },
    {
      name: 'accountHolderSearch',
      displayName: 'Customer name',
      type: 'INPUT',
    },
    {
      name: 'accountHolderIds',
      displayName: 'Customer Account ID',
      type: 'INPUT',
    },
    {
      name: 'financialAccountIds',
      displayName: 'Financial Account ID',
      type: 'INPUT',
    },
    {
      name: 'transactionOccurrenceType',
      displayName: 'Transaction occurrence type',
      type: 'RADIO',
      options: [
        { label: 'Single', value: TransactionOccurrenceType.SINGLE },
        { label: 'Recurring', value: TransactionOccurrenceType.RECURRING },
        { label: 'Fixed number', value: TransactionOccurrenceType.FIXED_NUMBER },
      ],
    },
    {
      name: 'paymentFrequency',
      displayName: 'Payment frequency',
      type: 'RADIO',
      options: [
        { label: 'Daily', value: PaymentFrequency.DAILY },
        { label: 'Weekly', value: PaymentFrequency.WEEKLY },
        { label: 'Biweekly', value: PaymentFrequency.BIWEEKLY },
        { label: 'Monthly', value: PaymentFrequency.MONTHLY },
        { label: 'Quarterly', value: PaymentFrequency.QUARTERLY },
        { label: 'Yearly', value: PaymentFrequency.YEARLY },
      ],
    },
    {
      name: 'amount',
      displayName: 'Transaction amount',
      type: 'INPUT',
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Completed', value: 'COMPLETED' },
        { label: 'Failed', value: 'FAILED' },
        { label: 'Suspended', value: 'SUSPENDED' },
        { label: 'Cancelled', value: 'CANCELLED' },
      ],
    },
    {
      name: 'statusReason',
      displayName: 'Status reason',
      type: 'INPUT',
    },
  ];

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 250, isSortable: true, colType: 'date-time' },
    { name: 'Customer Name', prop: 'customerName', flexSize: 250 },
    { name: 'Financial Account', prop: 'financialAccountDisplay', flexSize: 250, colType: 'two-line-col' },
    { name: 'Occurrence Type', prop: 'transactionOccurrenceType', fixedSize: 150 },
    { name: 'Frequency', prop: 'paymentFrequency', fixedSize: 150 },
    { name: 'Amount', prop: 'amount', colType: 'amount', fixedSize: 150, colAlign: 'right' },
    {
      name: 'Status',
      prop: 'status',
      sortProp: 'latestStatus',
      fixedSize: 140,
      colType: 'status-tag',
      colAlign: 'right',
    },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  constructor(
    private adaRegistrationService: AdaRegistrationService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initFilterList();
  }

  initFilterList(): void {
    this.adaRegistrationService.adaRegistrationListFilter.subscribe({
      next: (filters: FilterValues) => {
        if (isEmpty(filters)) {
          this.adaRegistrationService.getAdaRegistrationFilterParams();
        } else {
          this.activeFilters = filters;
        }
      },
    });
  }

  onSearch(event: string): void {
    if (!event) {
      this.onSearchReset();
      return;
    }
    this.searchString = event;
    this.getRegistrations({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }
    this.searchString = undefined;
    this.getRegistrations({ page: 0 });
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.adaRegistrationService.setAdaRegistrationFilterParams(this.activeFilters);
    this.getRegistrations({ page: 0 });
  }

  getRegistrations({ page, size, sortParams }: FetchData): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.store.dispatch(
      AdaRegistrationListActions.loadAdaRegistrations({
        params: {
          page: page ?? 0,
          size,
          searchString: this.searchString,
          sortParams: this.sortParams,
          activeFilters: this.activeFilters,
          accountHolderIds: this.accountHolderIds,
        },
      }),
    );
  }

  onRowClick({ id }: AdaRegistration): void {
    this.router.navigate([id], {
      relativeTo: this.activatedRoute,
    });
  }
}
