import { AbstractControl, ValidationErrors } from '@angular/forms';
import { formCheckboxGroup, formRadioGroup, RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { LinxCustomerType } from '@shared/constants';
import { compact } from 'lodash-es';

export const linxRequestProfileFields = (customerType?: LinxCustomerType): RktFormFieldConfig[] => {
  return [
    {
      fieldGroup: [
        formCheckboxGroup({
          key: 'personalInfo',
          label: 'Personal Information',
          className: 'linx-checkbox-enhance-fields',
          props: {
            options: [
              { value: 'firstName', label: 'First name' },
              { value: 'lastName', label: 'Last name' },
              { value: 'middleName', label: 'Middle name' },
              { value: 'email', label: 'Email' },
              { value: 'salutation', label: 'Salutation' },
              { value: 'sex', label: 'Sex' },
              { value: 'socialId', label: 'SSN' },
              { value: 'socialIdCountryCode', label: 'SSN Country' },
              { value: 'dateOfBirth', label: 'Date of Birth' },
              { value: 'cityOfBirth', label: 'City of Birth' },
              { value: 'countryOfBirthCode', label: 'Country of Birth' },
              { value: 'citizenshipCountryCode', label: 'Citizenship Country' },
            ],
          },
          expressions: {
            hide: () => customerType !== 'INDIVIDUAL',
          },
        }),
        formCheckboxGroup({
          key: 'businessInfo',
          label: 'Business Information',
          className: 'linx-checkbox-enhance-fields',
          props: {
            options: [
              { value: 'businessName', label: 'Business Name on Account' },
              { value: 'contacts', label: 'Contact Person' },
              { value: 'doingBusinessAsName', label: 'Business DBA Name' },
              { value: 'legalName', label: 'Business Legal Name' },
              { value: 'legalEntityType', label: 'Legal Entity type' },
              { value: 'dateOfFormation', label: 'Date of Formation' },
              { value: 'taxId', label: 'Business Tax ID' },
              { value: 'taxIdCountryCode', label: 'Business Tax ID Country (US)' },
              { value: 'industryCode', label: 'Industry' },
              { value: 'phoneNumber', label: 'Primary Phone of Business' },
            ],
          },
          expressions: {
            hide: () => customerType !== 'BUSINESS',
          },
        }),
        formRadioGroup({
          key: 'phoneNumber',
          className: 'linx-checkbox-enhance-fields',
          label: 'Phone Number Options',
          props: {
            options: [
              { value: 'MOBILE', label: 'Mobile' },
              { value: 'WORK', label: 'Work' },
              { value: 'HOME', label: 'Home' },
            ],
          },
          expressions: {
            hide: () => customerType !== 'INDIVIDUAL',
          },
        }),
        formCheckboxGroup({
          key: 'addresses',
          label: 'Address Options',
          className: 'linx-checkbox-enhance-fields',
          props: {
            options: [
              { value: 'PHYSICAL', label: 'Physical' },
              { value: 'MAILING', label: 'Mailing' },
              { value: 'SHIPPING', label: 'Shipping' },
            ],
          },
        }),
      ],
      className: 'linx-checkbox-group',
      validators: {
        required: (control: AbstractControl): ValidationErrors | null => {
          const controlArray = compact(Object.keys(control.value || {}).map((key) => control.value[key]));
          const nonEmpty: string[] = [];
          controlArray.forEach((item) => {
            const selectedOptions = Object.keys(item).filter((el) => item[el]);
            nonEmpty.push(...selectedOptions);
          });
          return nonEmpty.length ? { required: { message: 'error' } } : null;
        },
      },
    },
  ];
};

export const idDocumentIndividualFields = [
  formCheckboxGroup({
    key: 'documentTypes',
    label: '',
    className: 'linx-checkbox-enhance-fields',
    props: {
      options: [
        { value: 'driving-license', label: 'Drivers License' },
        { value: 'passport', label: 'Passport' },
        { value: 'other', label: 'Other' },
      ],
    },
  }),
];

export const idDocumentBusinessFields = [
  formCheckboxGroup({
    key: 'documentTypes',
    label: '',
    className: 'linx-checkbox-enhance-fields',
    props: {
      options: [
        { value: 'formation', label: 'Formation' },
        { value: 'tax', label: 'Tax' },
        { value: 'ownership', label: 'Ownership' },
        { value: 'other', label: 'Other' },
      ],
    },
  }),
];
