import { AfterViewChecked, ChangeDetectorRef, Component, inject } from '@angular/core';
import { isUndefined } from 'lodash-es';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DatatableColumn, DatatableSorting } from '@rocketfinancialcorp/rocket-ui/table';
import { FilterField, FilterValues, RequestPageParams, TransactionListItem } from '@shared/models';
import { NavigationService } from '@shared/services';
import { transactionFeature, TransactionsActions } from '@shared/store';
import { activeFilters } from '@shared/utils';

@Component({
  selector: 'app-card-transaction-list',
  templateUrl: './card-transaction-list.component.html',
})
export class CardTransactionListComponent implements AfterViewChecked {
  navigationService = inject(NavigationService);
  router = inject(Router);
  store = inject(Store);
  ref = inject(ChangeDetectorRef);
  activatedRoute = inject(ActivatedRoute);

  filters: FilterField[] = [
    {
      name: 'date',
      displayName: 'Date',
      type: 'DATE',
      options: [
        { label: 'Default: 30 days', value: 'DEFAULT' },
        { label: 'Today', value: 'TODAY' },
        { label: 'Yesterday', value: 'YESTERDAY' },
        { label: 'This week', value: 'THIS_WEEK' },
        { label: 'This month', value: 'THIS_MONTH' },
        { label: 'Custom range', value: 'CUSTOM' },
      ],
      defaultValue: 'DEFAULT',
      props: {
        maxMonths: '15',
      },
    },
    {
      name: 'maskedDebitAccountNumber',
      displayName: 'From Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'maskedCreditAccountNumber',
      displayName: 'To Account',
      type: 'INPUT',
      props: {
        label: 'Account No.',
        maxLength: '20',
        validationPattern: '[0-9*]*',
        validationPatternMessage: 'Account Number only allow numbers (digits) and asterisk',
      },
    },
    {
      name: 'amount',
      displayName: 'Amount',
      type: 'AMOUNT',
    },
    {
      name: 'latestStatus',
      displayName: 'Status',
      type: 'RADIO',
      options: [
        { label: 'All', value: undefined },
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Cleared', value: 'CLEARED' },
        { label: 'Declined', value: 'DECLINED' },
        { label: 'Error', value: 'ERROR' },
        { label: 'New', value: 'NEW' },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Processing', value: 'PROCESSING' },
        { label: 'Settled', value: 'SETTLED' },
        { label: 'Reversed', value: 'REVERSED' },
      ],
    },
  ];

  activeFilters: FilterValues = {};

  get activeFiltersCount(): number {
    return Object.keys(this.activeFilters).length;
  }

  transactions$ = this.store.select(transactionFeature.selectCardTransactions);

  totalElements$ = this.store.select(transactionFeature.selectCardTransactionsTotalElements);

  loading$ = this.store.select(transactionFeature.selectIsLoading);

  columns: DatatableColumn[] = [
    { name: 'Created At', prop: 'createdAt', fixedSize: 150, isSortable: true, colType: 'date-time' },
    { name: 'Merchant Name', prop: 'merchantName', isSortable: true, flexSize: 200 },
    { name: 'Transaction ID', prop: 'id', colType: 'short-id', flexSize: 150 },
    { name: 'Transaction Type', prop: 'transactionType', flexSize: 150 },
    { name: 'Payment Reason', prop: 'paymentReason', flexSize: 150 },
    { name: 'Amount', prop: 'amount', fixedSize: 100 },
    { name: 'Currency', prop: 'currency', fixedSize: 100, colAlign: 'right' },
    { name: 'Status', prop: 'status', fixedSize: 160, colType: 'status-tag', colAlign: 'right' },
  ];

  sortParams: DatatableSorting = { key: 'createdAt', sortProp: 'createdAt', sortDir: 'desc' };

  searchString?: string;

  get activePage(): number {
    const routePage = this.activatedRoute?.snapshot?.queryParams?.page;
    return routePage ? parseInt(routePage, 10) : 0;
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  updateQueryParams(page: RequestPageParams['page']): void {
    if (!isUndefined(page) && this.activePage !== page) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: page > 0 ? page : undefined },
        queryParamsHandling: 'merge',
      });
    }
  }

  onSearch(searchString?: string): void {
    if (!searchString) {
      this.onSearchReset();
      return;
    }
    this.searchString = searchString;
    this.getCardTransactions({ page: 0 });
  }

  onSearchReset(): void {
    if (!this.searchString) {
      return;
    }
    this.searchString = undefined;
    this.getCardTransactions({ page: 0 });
  }

  getCardTransactions({ page, sortParams }: RequestPageParams): void {
    this.updateQueryParams(page);

    if (sortParams) {
      this.sortParams = sortParams;
    }

    this.store.dispatch(
      TransactionsActions.loadCardTransactions({
        params: {
          page: page ?? 0,
          searchString: this.searchString,
          sortParams: sortParams ?? this.sortParams,
          activeFilters: { ...this.activeFilters },
        },
      }),
    );
  }

  onRowClick({ id }: TransactionListItem): void {
    this.router.navigateByUrl(`/app/cards/transaction-activity/${id}`);
  }

  applyFilters(filterValues: FilterValues): void {
    this.activeFilters = activeFilters(filterValues);
    this.getCardTransactions({ page: 0 });
  }
}
