<div class="entity-actions">
  <button
    *ngIf="beneficiary?.activityState !== 'SUSPENDED'"
    class="action-button action-button-suspend"
    [disabled]="!!(false | accessControl | async)"
    (click)="btnClicked.emit('SUSPEND')"
    title="Suspend Beneficiary"
  >
    <rkt-icon name="suspend"></rkt-icon>
  </button>
  <button
    *ngIf="beneficiary?.activityState === 'SUSPENDED'"
    class="action-button action-button-unsuspend"
    [disabled]="!!(false | accessControl | async)"
    (click)="btnClicked.emit('UNSUSPEND')"
    title="Unsuspend Beneficiary"
  >
    <rkt-icon name="resume"></rkt-icon>
  </button>
  <button
    class="action-button action-button-delete"
    title="Delete Beneficiary"
    [disabled]="isControlsDisabled | accessControl | async"
    (click)="btnClicked.emit('DELETE')"
  >
    <rkt-icon name="trash"></rkt-icon>
  </button>
</div>
