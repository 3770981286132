import { createAction, props } from '@ngrx/store';
import { AdaRegistration, AdaRegistrationEditParams, ErrorMessage } from '@shared/models';

export namespace AdaRegistrationActions {
  export const loadAdaRegistrationDetails = createAction('[Debit Authorizations API] Load Debit Authorization');

  export const loadAdaRegistrationDetailsSuccess = createAction(
    '[Debit Authorizations API] Load Debit Authorization Success',
    props<{ adaRegistration: AdaRegistration }>(),
  );

  export const loadAdaRegistrationDetailsError = createAction(
    '[Debit Authorizations API] Load Debit Authorization Error',
    props<{ error: ErrorMessage }>(),
  );

  export const initialize = createAction('[Debit Authorization Page] Initialize');

  export const updateAdaRegistrationStatus = createAction(
    '[Debit Authorizations API] Update Debit Authorization Status',
    props<{ action: string }>(),
  );

  export const updateAdaRegistrationStatusError = createAction(
    '[Debit Authorizations API] Update Debit Authorization Status Error',
    props<{ error: ErrorMessage; action: string }>(),
  );

  export const updateAdaRegistration = createAction(
    '[Debit Authorizations API] Update Debit Authorization',
    props<{ payload: AdaRegistrationEditParams }>(),
  );

  export const updateAdaRegistrationError = createAction(
    '[Debit Authorizations API] Update Debit Authorization Error',
    props<{ error: ErrorMessage }>(),
  );
}
